import React from 'react'
import DCAImage from 'components/DCAImage'
import images from 'assets/images/images'

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0,0,0,0.3)',
    display: 'flex',
    zIndex: 1001
  },
  image: {
    height: '60px',
    width: '60px',
  },
} as const

const LoadingModal = (): React.ReactElement => (
  <div className='app-loading' style={styles.container}>
    <DCAImage alt='loading' src={images.loading} style={styles.image} />
  </div  >
)

export default LoadingModal
