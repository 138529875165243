import { toast } from 'react-toastify'
import { ProductType, SearchItem } from 'types/searchType'
import React, { useState } from 'react'
import { Card, Col, FormCheck, Row, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { productCompareAnalytics } from 'config/adobeAnalytics/analytics'
import { TRANSLATIONS } from 'config/constants'
import { findShortTextValue } from 'config/libs/helpers'
import { getPageContentWithCallback } from 'redux/reducers/pageContentReducer'
import { selectTranslations } from 'redux/selector'
import useNavigation from 'hooks/useNavigation'
import DCAImage from 'components/DCAImage'
import { selectProductComparison } from '../../../redux/selector/comparisonSelector'
import { ProductListItemType } from '../../../types'
import { useProductComparison } from '../../Comparison/hooks/useProductComparison'
import { useGlobalModalContext } from '../../Modal/GlobalModal'

type ProductItemProps = {
  productData: SearchItem
}

const ProductItem = ({ productData }: ProductItemProps) => {
  const {
    id,
    name,
    images,
    texts,
    productType,
    partnumberFormatted,
    priceGrossFormatted,
    priceNetFormatted,
  } = productData

  const { onDynamicNavigate } = useNavigation()
  const translations = useSelector(selectTranslations)

  const dispatch = useDispatch()
  const { showModal, hideModal } = useGlobalModalContext()
  const { onAddOrRemoveProductCompare } = useProductComparison()
  const [favoriteIconLoading, setLoading] = useState(false)
  const productCompare = useSelector(selectProductComparison)

  const isCompare = !!productCompare.find((product) => product.id === Number(id))

  const notPartCurrentProductRange =
    translations?.not_part_current_product_range ?? TRANSLATIONS.notPartCurrentProductRange

  const imageUrl = images ? images[0]?.urls?.find((url) => url?.type === 'full') : undefined

  const isPastProduct = productType === ProductType.PastProduct
  const grayImgClass = isPastProduct ? 'img-disabled' : ''

  const onGoNextPage = () => onDynamicNavigate(id)

  const renderShortDesc = () => {
    if (!texts || texts.length === 0) return null

    const shortDesc = findShortTextValue(texts)

    if (!shortDesc) return null

    return <div className='search-card-desc'>{shortDesc}</div>
  }

  const renderDiscontinuedWarning = () => (
    <Row className='d-flex align-items-center'>
      <Col md='2'>
        <i className='karcher-icon warning-icon'>&#xe814;</i>
      </Col>
      <Col md='10'>
        <span className='discontinued-span'>{notPartCurrentProductRange}</span>
      </Col>
    </Row>
  )
  const orderNumberText = translations.order_number ?? 'Price Gross'
  const grossPriceText = translations.gross_priceNumber ?? 'Price Gross'
  const netPriceText = translations.net_priceNumber ?? 'Price Net'

  const priceNetExist = priceNetFormatted ?? false
  const priceGrossExist = priceGrossFormatted ?? false

  const onAddProductToFavorite = ({ id, name }: { id: number; name: string }) => {
    setLoading(true)
    dispatch(
      getPageContentWithCallback({
        pageId: id,
        async onSuccess(pageContent) {
          setLoading(false)
          showModal('FAVORITES_MODAL', {
            showModal: true,
            itemName: name,
            pageContent: pageContent,
            handleClose: hideModal,
          })
        },
        onFailure() {
          setLoading(false)
        },
      }),
    )
  }

  const onAddProductToComparison = (
    event: React.MouseEvent<HTMLDivElement>,
    productData: ProductListItemType,
  ) => {
    event.stopPropagation()
    onAddOrRemoveProductCompare(productData)
  }

  const onCopy = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    navigator.clipboard.writeText(partnumberFormatted)
    toast(translations.copied || TRANSLATIONS.copied, { autoClose: 1000 })
    event.stopPropagation()
  }

  const handleClick = (event: any) => {
    const selectedText = window.getSelection()?.toString()
    if (selectedText) {
      event.stopPropagation()
      return
    }
    onGoNextPage()
  }

  return (
    <Card
      id={id?.toString()}
      tabIndex={0}
      className='card-wrapper-no-border cursor-pointer'
      onClick={onGoNextPage}
    >
      <FormCheck
        readOnly
        checked={isCompare}
        label={translations.compare ?? 'Compare'}
        onClick={(event) => {
          onAddProductToComparison(event, productData as any)
          productCompareAnalytics(productCompare)
        }}
      />
      <span
        tabIndex={0}
        role='button'
        className='favorite-form'
        onClick={(event) => {
          onAddProductToFavorite({ id: parseInt(id?.toString()), name })
          event.stopPropagation()
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {favoriteIconLoading ? (
            <Spinner className='favorite-icon' />
          ) : (
            <p className='karcher-icon favorite-icon'>&#xe843;</p>
          )}
          <p className='favorite-text'>{translations.add_to_fav ?? 'Add to favourites'}</p>
        </div>
      </span>
      <DCAImage type='lazyImage' src={imageUrl?.url} className={`${grayImgClass} ak-card-img`} />
      <div onClick={handleClick}>
        <Card.Text className='search-card-title'>{name}</Card.Text>
        <Card.Text className='search-card-bold-text'>
          {orderNumberText}: {partnumberFormatted}
          <FontAwesomeIcon className='copy-icon' icon={faCopy} onClick={onCopy} />
        </Card.Text>
        {priceNetExist && (
          <Card.Text className='search-card-bold-text'>
            {netPriceText}: {priceNetFormatted}
          </Card.Text>
        )}
        {priceGrossExist && (
          <Card.Text className='search-card-bold-text'>
            {grossPriceText}: {priceGrossFormatted}
          </Card.Text>
        )}
        {renderShortDesc()}
      </div>

      {isPastProduct && renderDiscontinuedWarning()}
    </Card>
  )
}

export default ProductItem
