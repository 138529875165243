import clanProNarrBold from 'assets/fonts/ClanPro/ClanPro-NarrBold.otf'
import clanProNarrBook from 'assets/fonts/ClanPro/ClanPro-NarrBook.otf'
import images from 'assets/images/images'
import { FONT_ALIAS } from 'electron-shared'
import moment from 'moment'
import { StructureItemType } from 'types'
import { formatAnchorTag } from './pdfHelper'
import { pageBreak as pageBreakStyle } from './styles'
import { RibbonData } from './types'

export const getCustomHTML = (data: {
  ribbonData?: RibbonData
  breadCrumbData: StructureItemType[]
  pageStyles: string
  title?: string
}) => {
  const { ribbonData, breadCrumbData, pageStyles, title } = data
  const htmlDOM = getDefaultHTML({ pageStyles, title })
  const contentHTML = htmlDOM.getElementById('main')!
  contentHTML.innerHTML += toBreadcrumbHTML(breadCrumbData)
  contentHTML.innerHTML += toRibbonHTML(ribbonData)

  return htmlDOM
}

export const toRibbonHTML = (data?: RibbonData) => {
  if (!data?.headline && !data?.description) return ''

  const { headline, subHeadline, description } = data

  const str = `<div class="ribbon-text">
      <h1>${headline?.toLocaleUpperCase()}</h1>
      ${
        subHeadline
          ? `<p class="bold" style="margin-top: 5px; margin-bottom: 0px">${subHeadline}</p>`
          : ''
      }
      ${description ? `<p style="margin-top: 5px; margin-bottom: 0px;">${description}</p>` : ''}
    </div>
    `
  return str
}

const toBreadcrumbHTML = (breadcrumbData: StructureItemType[]) => {
  if (!breadcrumbData.length) return ''
  const visitedPagesArr = breadcrumbData.slice(0, breadcrumbData.length - 1)
  let visitedPagesStr = ''
  if (visitedPagesArr.length) {
    visitedPagesArr.forEach((item) => {
      visitedPagesStr += item ? `${item.title} &gt; ` : ''
    })
  }
  const lastEle = breadcrumbData[breadcrumbData.length - 1]?.title ?? ''

  return `
    <div style="display: flex;font-size: 9px; margin-top: 10px">
      <span>
      ${visitedPagesStr}
      </span>&nbsp;
      <span class="bold"> ${lastEle}</span>
    </div>
  `
}

const pageBreak = () =>
  `
    <p class="page-break" style="text-align: right; font-size: 8px;">
      ${moment().format('DD.MM.YYYY')}
    </p>
  `

const horizontalRule = () => `
  <div style="padding-top: 25px">
    <div class="horizontal-rule"></div>
  </div>
  `

const getCard = (imgUrl: string | undefined, headline?: string, description?: string) => {
  const imgStr = imgUrl ? `<img src="${imgUrl}" alt="" srcset="">` : ''
  const headlineStr = headline ? `<h3>${headline}</h3>` : ''
  const descriptionStr = description ? `<p>${description}</p>` : ''
  return `
    <div class="card">
      ${imgStr}
      ${formatAnchorTag(headlineStr)}
      ${formatAnchorTag(descriptionStr)}
    </div>
  `
}

export const getDefaultHTML = (
  data: { pageStyles: string; title?: string },
  renderLogo: boolean = true,
) =>
  new DOMParser().parseFromString(
    `
    <html lang="en">
    <head>
        <meta http-equiv="content-type" content="text/html;charset=UTF-8" />
        <title>${data.title || 'Kärcher Programme'}</title>
        <style>
          @page {
            size: auto;
            margin: 10px 40px 40px 40px;
          }

          ${commonStyle}

          @font-face {
            font-family: "Clan Pro News";
            font-weight: bold;
            src:url("${
              window.electron?.isDesktop() ? FONT_ALIAS.clanProNarrBold : clanProNarrBold
            }");
          }

          @font-face {
            font-family: "Clan Pro News";
            font-weight: normal;
            src: url("${
              window.electron?.isDesktop() ? FONT_ALIAS.clanProNarrBook : clanProNarrBook
            }");
          }
          

          ${data.pageStyles}
        </style>
      </head>

      <body id="pdf_body">
        <div id="page">
          <div id="main" style="position: relative; float: none; font-family: Clan Pro News;">
          ${renderLogo ? logoHTML : ''}
          </div>
        </div>
      </body>

    </html>
`,
    'text/html',
  )

const currentDate = moment().format('DD.MM.YYYY')

export const logoHTML = `
  <div id="header" class="row" style="justify-content: space-between;">
    <img class="ak-logo" id="logo" alt="logo" style="width: 85px;height: 22px;" src="${images.logo}">
    <p style="font-size: 8px;">${currentDate}</p>
  </div>
  <div style="margin-top: 10px; width:100%;background:gray;height: 2.5px;"></div>
  `

const commonStyle = `
  .page-break {
    ${pageBreakStyle}
    padding-top: 50px;
  }
  .page-break-auto {
    ${pageBreakStyle}
  }
  body {
    -webkit-print-color-adjust: exact;
    float: none;
    background: white;
    padding: 0px;
    margin: 0px;
  }
  a {
    text-decoration: none;
  }
  #page {
    padding-top: 30px;
    width: 100%;
    border-style: solid;
    border-width: 0px;
    border-color: black;
    justify-content: center;
    align-items: center;
    display: flex;
    background: white;
  }
  #main {
    width: 100%;
    font-family: "Clan Pro News";
    font-weight: 100;
    font-size: 12px;
    background: white;
    font-weight: normal;
  }
  .bold {
    font-weight: bold;
  }
  .line {
    width: 100%;
    background: #e3e3e3;
    height: 2px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  h5 {
    margin-bottom: 0px;
  }
  .small {
    font-size: 10px;
  }
  .medium {
    font-size: 11px;
  }
  h3 {
    /* margin-bottom: 0px; */
  }
  h4 {
    font-size: 12px;
    margin-bottom: 0px;
  }
  .row {
    display: flex;
    flex-direction: row;
  }
  .column {
    display: flex;
    flex-direction: column;
  }
  .horizontal-rule {
    width:100%;
    background:gray;
    height: 1.5px;
    break-after: page;
  }
`
export { toBreadcrumbHTML, pageBreak, getCard, horizontalRule }
