import images from 'assets/images/images'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PAGE_IDS } from 'config/constants'
import { selectAppOnline, selectHasProductFinder } from 'redux/selector'
import DCAImage from 'components/DCAImage'
import { HeadlineDataType } from './Header'
import SearchBar from './SearchBar'
import 'styles/components/breadcrumb.scss'
import 'styles/components/header.scss'

type HeaderProps = {
  pageId?: number
  headlineData?: HeadlineDataType
  onGoRootPage: () => void
  onGoProductFinder: () => void
}

const HeaderUI = (props: HeaderProps): React.ReactElement => {
  const isAppOnline = useSelector(selectAppOnline)
  const { pageId, headlineData, onGoRootPage, onGoProductFinder } = props
  const hasProductFinder = useSelector(selectHasProductFinder)

  const isBusinessEntry = pageId === PAGE_IDS.BUSINESS_UNIT_ENTRY
  const isShowProductFinder =
    pageId !== PAGE_IDS.PRODUCT_FINDER &&
    hasProductFinder &&
    headlineData?.headline === 'Professional'

  const renderLogo = (): React.ReactElement => {
    return (
      <Col md='2'>
        {isBusinessEntry ? (
          <DCAImage id='onboarding-logo' className='ak-logo' alt='logo' src={images.logo} />
        ) : (
          <Link
            to='/business-entry'
            state={{
              pageId: PAGE_IDS.BUSINESS_UNIT_ENTRY,
            }}
          >
            <DCAImage id='onboarding-logo' className='ak-logo' alt='logo' src={images.logo} />
          </Link>
        )}
      </Col>
    )
  }

  const renderHeadline = (): React.ReactElement | null => {
    if (isBusinessEntry) {
      return null
    }

    return (
      <Col md='6'>
        {headlineData && (
          <span onClick={onGoRootPage} className='headline-text'>
            {headlineData.headline}
          </span>
        )}

        {isShowProductFinder && (
          <span onClick={onGoProductFinder} className='product-finder-text'>
            PRODUCT FINDER
          </span>
        )}
      </Col>
    )
  }

  const renderRight = (): React.ReactElement => {
    if (isBusinessEntry)
      return (
        <Col md={{ span: 2, offset: 8 }} style={{ display: 'flex', justifyContent: 'right' }}>
          <Link style={{ color: '#000', textDecoration: 'none' }} to='/settings'>
            <i className='karcher-icon'>&#xe834;</i>
          </Link>
        </Col>
      )

    return (
      <>
        <Col md='4'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <div className='flex-fill me-3'>
              <SearchBar />
            </div>
            <FontAwesomeIcon
              id='onboarding-icon-status'
              className={`${isAppOnline ? 'icon-app-online' : 'icon-app-offline'}`}
              size='1x'
              icon={faCircle}
            />
          </div>
        </Col>
      </>
    )
  }

  return (
    <Container className='header-wrapper py-3'>
      <Row>
        {renderLogo()}

        {renderHeadline()}

        {renderRight()}
      </Row>
    </Container>
  )
}

export default HeaderUI
