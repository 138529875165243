import { RootState } from 'redux/reducers'
import { FavoriteContentType, FavoriteFolderType } from 'types'

export const selectFavoritesContent = (state: RootState): FavoriteContentType[] =>
  state?.favorites.favoritesContent

export const selectFavoritesFolder = (state: RootState): FavoriteFolderType[] =>
  state?.favorites.favoritesFolder

export const selectSelectedFolderId = (state: RootState): string =>
  state?.favorites.selectedFolderId
