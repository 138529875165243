import { toast } from 'react-toastify'
import React, { useState } from 'react'
import { Card, Col, FormCheck, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ProductListItemType } from 'types'
import { productCompareAnalytics } from 'config/adobeAnalytics/analytics'
import { TRANSLATIONS } from 'config/constants'
import { getPageContentWithCallback } from 'redux/reducers/pageContentReducer'
import { selectTranslations } from 'redux/selector'
import { selectProductComparison } from 'redux/selector/comparisonSelector'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'
import DCAImage from 'components/DCAImage'
import 'styles/pages/productList.scss'

type UIProps = {
  product: ProductListItemType
  onGoNextPage: (pageId: number) => void
  onAddProductToComparison: (
    event: React.MouseEvent<HTMLDivElement>,
    product: ProductListItemType,
  ) => void
}
const ProductItem = ({ product, onGoNextPage, onAddProductToComparison }: UIProps) => {
  const dispatch = useDispatch()
  const translations = useSelector(selectTranslations)
  const productCompare = useSelector(selectProductComparison)
  const { showModal, hideModal } = useGlobalModalContext()
  const [favoriteIconLoading, setLoading] = useState(false)
  const { id, name, images, texts, partnumberFormatted, priceNetFormatted, priceGrossFormatted } =
    product

  const imageUrl = images[0].urls.find((url) => url.type === 'full')
  const orderNumber = translations.order_number ? translations.order_number : 'Part Number'
  const grossPriceNumber = translations.gross_priceNumber
    ? translations.gross_priceNumber
    : 'Price Gross'
  const netPriceNumber = translations.net_priceNumber ? translations.net_priceNumber : 'Price Net'
  const isCompare = !!productCompare.find((product) => product.id === Number(id))

  const onAddProductToFavorite = ({ id, name }: { id: number; name: string }) => {
    setLoading(true)
    dispatch(
      getPageContentWithCallback({
        pageId: id,
        async onSuccess(pageContent) {
          setLoading(false)
          showModal('FAVORITES_MODAL', {
            showModal: true,
            itemName: name,
            pageContent: pageContent,
            handleClose: hideModal,
          })
        },
        onFailure() {
          setLoading(false)
        },
      }),
    )
  }

  const handleClick = () => {
    const selectedText = window.getSelection()?.toString()
    if (selectedText) {
      return
    }
    onGoNextPage(parseInt(id))
  }

  const onCopy = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    navigator.clipboard.writeText(partnumberFormatted)
    toast(translations.copied || TRANSLATIONS.copied, { autoClose: 1000 })
    event.stopPropagation()
  }

  return (
    <Col>
      <div onClick={(event) => onAddProductToComparison(event, product)} className='cursor-pointer'>
        <FormCheck
          readOnly
          checked={isCompare}
          label={translations.compare ?? 'Compare'}
          onClick={(event) => {
            onAddProductToComparison(event, product)
            productCompareAnalytics(productCompare)
          }}
        />
      </div>
      <Card key={id} className='card-wrapper'>
        <span
          tabIndex={0}
          role='button'
          className='favorite-form'
          onClick={(event) => {
            onAddProductToFavorite({ id: parseInt(id), name })
            event.stopPropagation()
          }}
        >
          <div className='horizontal-p'>
            {favoriteIconLoading ? (
              <Spinner className='favorite-icon' />
            ) : (
              <p className='karcher-icon favorite-icon'>&#xe843;</p>
            )}
            <p className='favorite-text'>{translations.add_to_fav ?? 'Add to favourites'}</p>
          </div>
        </span>

        <div className='export-text cursor-pointer' onClick={() => onGoNextPage(parseInt(id))}>
          <DCAImage type='cardImage' variant='top' src={imageUrl?.url || ''} />
        </div>
        <div className='text-left pt-3 cursor-pointer' onClick={handleClick}>
          <p className='left-p'>{name}</p>

          <div className='product-price'>
            {orderNumber} {`${partnumberFormatted}`}
            <FontAwesomeIcon className='copy-icon' icon={faCopy} onClick={onCopy} />
          </div>

          {priceNetFormatted && (
            <div className='product-price'>
              {netPriceNumber} {`${priceNetFormatted}`}
            </div>
          )}

          {priceGrossFormatted && (
            <div className='product-price'>
              {grossPriceNumber} {`${priceGrossFormatted}`}
            </div>
          )}

          {texts && <div className='product-desc'>{texts[0]?.value}</div>}
        </div>
      </Card>
    </Col>
  )
}

export default ProductItem
