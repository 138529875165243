import { useSelector } from 'react-redux'
import { TRANSLATIONS } from 'config/constants'
import { selectAppOnline, selectTranslations } from 'redux/selector'
import useNavigation from 'hooks/useNavigation'

type PropTypes = {
  zoovuIframeUrl: string
  showOnlineGuide: boolean
}

const ProductFinderUI = ({ zoovuIframeUrl, showOnlineGuide }: PropTypes) => {
  const { onGoBack } = useNavigation()
  const appOnline = useSelector(selectAppOnline)
  const translations = useSelector(selectTranslations)

  const renderOfflineContent = () => {
    return (
      <div className='content-error text-center p-5'>
        <h6 style={{ fontWeight: 'bolder' }}>
          {translations?.online_only || 'This content is only available in online mode.'}
        </h6>

        <button className='app-button mt-3' onClick={onGoBack}>
          {(
            translations.back_to_previous_page ?? TRANSLATIONS.backToPreViousPage
          ).toLocaleUpperCase()}
        </button>
      </div>
    )
  }

  if (!appOnline) return renderOfflineContent()

  if (!zoovuIframeUrl) return <div />

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ display: 'flex', flexGrow: 1 }}>
        <iframe key={zoovuIframeUrl} style={{ width: '100%' }} src={zoovuIframeUrl} />
      </div>
      {showOnlineGuide && (
        <h6 className='text-center mt-2' style={{ fontWeight: 'bolder' }}>
          {translations?.online_only || 'This content is only available in online mode.'}
        </h6>
      )}
    </div>
  )
}

export default ProductFinderUI
