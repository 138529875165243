import HorizontalRule from 'components/HorizontalRule'
import { PRODUCT_DETAILS_SECTIONS } from 'config/constants'
import { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import { VideoItemType } from 'types'

type VideoProps = {
  videos: VideoItemType[]
}

const Videos = ({ videos }: VideoProps) => {
  if (!videos || !videos.length) return null

  const translations = useSelector(selectTranslations)
  const headline = translations?.videos ?? 'Videos'

  const injectCustomScript = () => {
    const isExistCliplisterScript = !!document.querySelector('script#cliplister-id')
    if (isExistCliplisterScript) {
      return
    }

    const cliplisterScript = document.createElement('script')
    cliplisterScript.id = 'cliplister-id'
    cliplisterScript.src =
      'https://mycliplister.com/merge?cliplister=1.10&clviewer=1.35&videostage=1.24&innercontrols=1.11&clickablevideo=1.6&playbutton=1.2&previewimage=1.4&bufferingspinner=1.5'

    cliplisterScript.onload = () => {
      videos.forEach((video) => {
        const myDiv = document.getElementById(`cliplister-video-player-${video.id}`)
        if (!myDiv) {
          return
        }

        const cliplisterExecuteScript = document.querySelector(
          `script#custom-cliplister-script-${video.id}`,
        )
        if (cliplisterExecuteScript) {
          return
        }

        const script = document.createElement('script')
        script.id = `custom-cliplister-script-${video.id}`
        script.textContent = `
          new Cliplister.Viewer({
            parentID: "cliplister-video-player-${video.id}",
            customer: 156406,
            lang: "de,##",
            assetKeys: ["${video.id}"],
            keyType: 500,
            stage: { width: "100%", aspectRatio: "asset" },
            plugins: {
              InnerControls: {
                layer: 2,
                id: "controls",
                blacklist: ["share", "playback-speed", "quality"],
                template: {
                  type: "external",
                  source:
                    "https://mycliplister.com/static/viewer/assets/skins/kaercher/controls.html",
                },
              },
              ClickableVideo: { layer: 1 },
              BufferingSpinner: { layer: 3, width: 50, height: 50 },
              PlayButton: {
                id: "playButton",
                layer: 7,
                image:
                  "https://mycliplister.com/static/kaercher/skin/img/playbutton.png",
                width: 100,
                height: 100,
              },
              PreviewImage: { layer: 6 },
            },
          });
        `
        document.body.appendChild(script)
      })
    }

    document.body.appendChild(cliplisterScript)
  }

  useEffect(() => {
    const cleanup = () => {
      const cliplisterScript = document.querySelector('script#cliplister-id')
      if (cliplisterScript) {
        cliplisterScript.remove()
      }

      videos.forEach(({ id }) => {
        const cliplisterExecuteScript = document.querySelector(
          `script#custom-cliplister-script-${id}`,
        )
        if (cliplisterExecuteScript) {
          cliplisterExecuteScript.remove()
        }
      })
    }

    const initialize = () => {
      const cliplisterScript = document.querySelector('script#cliplister-id')
      if (cliplisterScript) {
        cliplisterScript.remove()
      }

      videos.forEach((video) => {
        const cliplisterExecuteScript = document.querySelector(
          `script#custom-cliplister-script-${video.id}`,
        )
        if (cliplisterExecuteScript) {
          cliplisterExecuteScript.remove()
        }
      })
    }

    initialize()
    injectCustomScript()

    return cleanup
  }, [])

  const renderVideos = () => {
    return (
      <Row>
        {videos.slice(0, 3).map((video) => {
          return (
            <Col key={video.id} md={4}>
              <div id={`cliplister-video-player-${video.id}`} />
            </Col>
          )
        })}
      </Row>
    )
  }

  return (
    <Container className='pt-4' id={PRODUCT_DETAILS_SECTIONS.VIDEOS}>
      <h6 className='ak-bold'>{headline.toUpperCase()}</h6>

      <HorizontalRule />

      {renderVideos()}
    </Container>
  )
}

export default Videos
