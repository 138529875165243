import { searchDb } from 'database/queries/searchDB'
import { formatSearchTerm } from 'helper/common'
import { getSearchQuery } from 'services'
import { SearchResponseType } from 'types/searchType'
import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { PAGE_IDS, TRANSLATIONS } from 'config/constants'
import { selectTranslations } from 'redux/selector'
import useDataFetcher from 'hooks/useDataFetcher'
import LoadingPage from 'features/Common/screens/LoadingPage'
import Page from 'components/Page/Page'
import SearchScreenUI from './Search.ui'

const SearchScreen = () => {
  const translations = useSelector(selectTranslations)
  const location = useLocation()
  const params = useParams()
  const { fetchData } = useDataFetcher()

  const { searchKey } = params as { searchKey: string }

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [dataResult, setDataResult] = useState<SearchResponseType>()

  const searchTitle = translations.search_result ?? TRANSLATIONS.search

  const onSearch = async () => {
    try {
      setIsLoading(true)
      const searchResult = (await fetchData(
        getSearchQuery,
        searchDb,
        formatSearchTerm(searchKey),
        formatSearchTerm(searchKey),
      )) as SearchResponseType

      if (searchResult) {
        const filteredSearchData = Object.entries(searchResult)
          .filter(([_, value]) => value.docCount > 0)
          .reduce((obj, [key, value]) => {
            obj[key] = value
            return obj
          }, {} as SearchResponseType)
        setDataResult(filteredSearchData)
      }
    } catch (error) {
      setDataResult(undefined)
      console.error('Error onSearch:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (searchKey) {
      onSearch()
    }
  }, [searchKey])

  const renderContent = () => {
    if (isLoading) {
      return <LoadingPage />
    }

    if (!dataResult) {
      return null
    }

    return <SearchScreenUI data={dataResult} searchKey={searchKey} key={location.key} />
  }

  return (
    <Page className='search-screen  px-0' pageId={PAGE_IDS.SEARCH_SCREEN} breadCrumbTitle={searchTitle}>
      {renderContent()}
    </Page>
  )
}

export default SearchScreen
