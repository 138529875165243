import Page from 'components/Page/Page'
import { LAYOUTS, PAGE_NAMES, PAGE_TYPES } from 'config/constants'
import { isObjectEmpty } from 'config/utils/CommonFunction'
import { getProductGroupDbById } from 'database/queries/product'
import ErrorPage from 'features/Common/screens/ErrorPage'
import LoadingPage from 'features/Common/screens/LoadingPage'
import useAnalytics from 'hooks/useAnalytics'
import useDataFetcher from 'hooks/useDataFetcher'
import useNavigation from 'hooks/useNavigation'
import usePreviousValue from 'hooks/usePreviousValue'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from 'redux/reducers'
import { getPageContent } from 'redux/reducers/pageContentReducer'
import { selectPageContent } from 'redux/selector'
import { getProductGroupQuery } from 'services'
import { ProductGroupItemType, ProductGroupResponseType } from 'types'
import ProductGroupOverviewUI from './ProductGroupOverview.ui'

const ProductGroupOverview = (): ReactElement => {
  const dispatch = useDispatch()
  const { onDynamicNavigate } = useNavigation()
  const { setPageInfoAnalytics } = useAnalytics()
  const { fetchData } = useDataFetcher()

  const { pageId } = useParams<{ pageId: string }>()

  const pageIdNumber = Number(pageId)
  const previousPageId = usePreviousValue(pageIdNumber)

  const pageData = useSelector((state: RootState) => selectPageContent(state, pageIdNumber))
  const { data, error, loading } = pageData ?? {}

  const [productGroups, setProductGroups] = useState<ProductGroupItemType[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)

  useEffect(() => {
    setPageInfoAnalytics({
      pageId: pageIdNumber,
      pageName: PAGE_NAMES.PRODUCT_GROUP_OVERVIEW,
      pageType: PAGE_TYPES.PRODUCT_GROUP_OVERVIEW,
    })
    if (!previousPageId || previousPageId !== pageIdNumber) {
      dispatch(getPageContent(pageIdNumber))
    }
  }, [dispatch, pageIdNumber, previousPageId])

  // Data handling
  useEffect(() => {
    const handleProductGroup = async () => {
      try {
        if (!data || !('content' in data)) {
          return
        }

        const productGroupOverviewObj = data.content.find(
          (item) => item.type === LAYOUTS.PRODUCT_GROUPS_OVERVIEW,
        )

        if (!productGroupOverviewObj) {
          return
        }

        const productGroupConfig = JSON.parse(productGroupOverviewObj.config || '{}')

        if (isObjectEmpty(productGroupConfig)) {
          productGroupConfig.productgroupId = null
        }

        const productGroupId = productGroupConfig.productgroupId as number

        const productGroupsResponse = await fetchData<number, ProductGroupResponseType>(
          getProductGroupQuery,
          getProductGroupDbById,
          productGroupId,
          productGroupId,
        )

        const productGroupsFilter = productGroupsResponse?.data?.filter((item) => {
          const [headerText] = item.texts

          return headerText
        })

        setProductGroups(productGroupsFilter)
      } catch (e) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    }

    if (loading === false) handleProductGroup()
  }, [data, loading])

  // Error handling
  useEffect(() => {
    if (error) {
      setIsError(true)
      setIsLoading(false)
    }
  }, [error])

  const onGoNext = (id: number) => {
    onDynamicNavigate(id)
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingPage />
    }

    if (isError) {
      return <ErrorPage />
    }

    if (data) {
      return (
        <ProductGroupOverviewUI data={data} productGroups={productGroups} onGoNext={onGoNext} />
      )
    }

    return null
  }

  return (
    <Page pageId={pageIdNumber} className='group-overview-container'>
      {renderContent()}
    </Page>
  )
}

export default ProductGroupOverview
