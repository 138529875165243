import { universal } from 'database/db'
import { IDcaMarking } from 'database/dbTypes'

const getMarkingDbById = async (markId: number): Promise<IDcaMarking | undefined> => {
  const content = await universal.dcaMarking.where({ id: markId }).first()
  return content
}

const addMarkingDb = async (data: IDcaMarking): Promise<boolean> => {
  await universal.dcaMarking.put(data)
  return true
}

const updateMarkingDb = async (data: Partial<IDcaMarking>): Promise<unknown> => {
  const markId = data?.id
  if (!markId) return

  const updatedData = await universal.dcaMarking.where({ id: markId }).modify({ ...data })
  return { data: updatedData }
}

const deleteMarkingDb = async (markId: number): Promise<unknown> => {
  const deletedData = await universal.dcaMarking.where({ id: markId }).delete()
  return deletedData
}

const getListMarkingDb = async (): Promise<IDcaMarking[]> => {
  const content = await universal.dcaMarking.toArray()
  return content
}

export { getMarkingDbById, addMarkingDb, getListMarkingDb, updateMarkingDb, deleteMarkingDb }
