import { getProductGroupDbById, getSubProductGroupDbByIds } from 'database/queries/product'
import { getProductGroupQuery, getSubProductGroupQuery } from 'services'
import {
  PageContentResponseType,
  ProductGroupItemType,
  ProductGroupResponseType,
  SubProductGroupResponseType,
} from 'types'
import { LAYOUTS } from 'config/constants'
import {
  productGroupListStyle,
  toFilterProductListHTML,
  toProductGroupListHTML,
} from '../ProductGroupListPDF/ProductGroupListPDF'
import { getBreadcrumbData } from '../pdfHelper'
import { logoHTML, toBreadcrumbHTML, toRibbonHTML } from '../pdfLayouts'
import { Config } from './type'
import { asyncFetchData, asyncGetPageContent } from './utils'

export const getProductGroupListHTML = async (pageId: number, config: Config) => {
  const { isAppOnline } = config
  const pageData = await asyncGetPageContent(pageId, isAppOnline)

  if (!pageData || !('content' in pageData)) return ''

  const productGroupListObj = pageData.content.find(
    (item) => item.type === LAYOUTS.PRODUCT_GROUPS_LIST,
  )
  if (!productGroupListObj) return ''
  const productGroupConfig = JSON.parse(productGroupListObj.config || '{}')
  if (!productGroupConfig.productgroupId) return ''

  const productGroupsResponse = await asyncFetchData<number, ProductGroupResponseType>(
    isAppOnline,
    getProductGroupQuery,
    getProductGroupDbById,
    productGroupConfig.productgroupId,
    productGroupConfig.productgroupId,
  )

  if (!productGroupsResponse) return ''

  const productGroupsFilter = productGroupsResponse?.data?.filter((item) => {
    const [headerText] = item.texts

    return headerText
  })

  const productGroupIds = productGroupsFilter.map((item) => parseInt(item.id))
  const subProductGroup = await asyncFetchData<number[], SubProductGroupResponseType>(
    isAppOnline,
    getSubProductGroupQuery,
    getSubProductGroupDbByIds,
    productGroupIds,
    productGroupIds,
  )

  const breadCrumbData = getBreadcrumbData(pageId, config.structure)
  const ribbonData = getRibbonData(pageData, productGroupsResponse.data)

  const breadCrumbHTML = toBreadcrumbHTML(breadCrumbData)
  const ribbonHTML = toRibbonHTML(ribbonData)

  const { isDropdownAvailable } = productGroupsResponse

  const productHTML = isDropdownAvailable
    ? toFilterProductListHTML([], config.translations)
    : toProductGroupListHTML(productGroupsResponse.data, subProductGroup?.data || [])

  return `
    <style>
    ${productGroupListStyle}
    </style>
    ${logoHTML}
    ${breadCrumbHTML}
    ${ribbonHTML}
    ${productHTML}
`
}

const getRibbonData = (
  pageData: PageContentResponseType,
  productGroups: ProductGroupItemType[],
) => {
  const layoutData = pageData.content
  const productGroupListObj = layoutData.find((item) => item.type === LAYOUTS.PRODUCT_GROUPS_LIST)
  const layoutConfig = JSON.parse(productGroupListObj?.config ?? '{}')

  const ribbonData = productGroups.find(
    (group) => String(group.id) === String(layoutConfig.productgroupId),
  )

  if (!ribbonData) return undefined

  const { name, texts } = ribbonData
  const ribbonTxt = texts.find((text) => text.type === 'header')
  const subHeadline = ribbonTxt?.headline ?? ''
  const description = ribbonTxt?.text ?? ''

  return {
    headline: name,
    subHeadline,
    description,
  }
}
