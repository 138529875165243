import Dexie, { Table } from 'dexie'
import { dbSeverVersion } from './dbFunctions'
import { commonSchema, homeAndGardenSchema, professionalSchema, universalSchema } from './dbSchema'
import {
  CommonSubTables,
  HomeSubTables,
  ProfessionalSubTables,
  UniversalSubTables,
} from './dbTypes'
import { migrateDBToVer5, universalSchemaVer5 } from './migrations/migrateDbVer5'

/**
 * Create the database with the given schema.
 * Follow the documentation of Dexie to learn more about the schema.
 * https://dexie.org/docs/Typescript
 */
class UniversalSub extends Dexie {
  dcaSettings!: Table<UniversalSubTables['IDcaSettings']>
  dcaFavFolder!: Table<UniversalSubTables['IDcaFavFolder']>
  dcaFavContent!: Table<UniversalSubTables['IDcaFavContent']>
  dcaProductCompare!: Table<UniversalSubTables['IDcaProductCompare']>
  dcaMarking!: Table<UniversalSubTables['IDcaMarking']>
  dcaOnboarding!: Table<UniversalSubTables['IDcaOnboarding']>
  dcaDownload!: Table<UniversalSubTables['IDcaDownload']>

  constructor() {
    super('AK_DCA_LOCAL')
    this.version(4).stores(universalSchema)

    this.version(5).stores(universalSchemaVer5).upgrade(migrateDBToVer5)
  }
}

class CommonSub extends Dexie {
  dcaProductGroup!: Table<CommonSubTables['IDcaProductGroup']>
  dcaSubProductGroup!: Table<CommonSubTables['IDcaSubProductGroup']>
  dcaProductList!: Table<CommonSubTables['IDcaProductList']>
  dcaStructure!: Table<CommonSubTables['IDcaStructure']>
  dcaTranslations!: Table<CommonSubTables['IDcaTranslations']>
  dcaPages!: Table<CommonSubTables['IDcaPages']>
  dcaSearchManualContent!: Table<CommonSubTables['IDcaSearchManualContent']>

  constructor() {
    super('AK_DCA_COMMON')
    this.version(dbSeverVersion).stores(commonSchema)
  }
}
class HomeSub extends Dexie {
  dcaProductDetails!: Table<HomeSubTables['IDcaProductDetails']>

  constructor() {
    super('AK_DCA_HOME_AND_GARDEN')
    this.version(dbSeverVersion).stores(homeAndGardenSchema)
  }
}

class ProfessionalSub extends Dexie {
  dcaProductDetails!: Table<ProfessionalSubTables['IDcaProductDetails']>

  constructor() {
    super('AK_DCA_PROFESSIONAL')
    this.version(dbSeverVersion).stores(professionalSchema)
  }
}

export const universal = new UniversalSub()
export const common = new CommonSub()
export const homeDb = new HomeSub()
export const professionalDb = new ProfessionalSub()
