import Dexie from 'dexie'
import { importInto } from 'dexie-export-import'
import { DOCUMENT_KEYS, DocumentType } from 'redux/download/download.reducer'
import { common, homeDb, professionalDb, universal } from './db'

export const dbSeverVersion = 4

export const dbMigration = async () => {
  try {
    const dbNames = await Dexie.getDatabaseNames()
    await Promise.all(dbNames.map((dbName) => deleteDatabase(dbName)))
  } catch (error) {
    console.error('Error migrating database:', error)
  }
}

export const resetOfflineDatabase = async () => {
  await Promise.all(DOCUMENT_KEYS.map(deleteDatabase))
}

export const getDatabase = (dbName: string) => {
  switch (dbName) {
    case 'AK_DCA_LOCAL':
      return universal
    case DocumentType.AK_DCA_HOME_AND_GARDEN:
      return homeDb
    case DocumentType.AK_DCA_COMMON:
      return common
    case DocumentType.AK_DCA_PROFESSIONAL:
      return professionalDb
    default:
      return null
  }
}

// make sure after delete, open the database
export async function deleteDatabase(dbName: string) {
  try {
    const db = getDatabase(dbName)
    if (db) {
      await db.delete()
      await db.open()
      await new Promise((resolve) => setTimeout(resolve, 1000))
    }
  } catch (error) {
    console.error(`Error deleting database ${dbName}:`, error)
  }
}

async function importDatabase(dbName: string, dbBlob: Blob) {
  try {
    const db = getDatabase(dbName)

    if (db) {
      if (!db.isOpen()) {
        await db.open()
      }
      await importInto(db, dbBlob)
    }
  } catch (error) {
    console.error('Error importing database:', error)
  }
}

export const replaceDatabase = async (dbName: string, blob: Blob) => {
  await deleteDatabase(dbName)
  await importDatabase(dbName, blob)
}
