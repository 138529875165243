import { storeCompletedDownload } from 'database/queries/download'
import { MainEvent, RendererEvent } from 'electron-shared'
import { Dispatch } from 'redux'
import { cleanupDataWhenFinishDownload } from './download.hook'
import {
  DocumentType,
  DownloadInfo,
  DownloadStatus,
  INIT_DOWNLOAD_DATA,
  overrideDownloadStatus,
  updateDownloadStatus,
} from './download.reducer'

export type DownloadTaskData = {
  id: string
  url: string
  fileName: string
  fileLocation: string
}

const tasksMap = new Map<string, DownloadInfo>()

// run only one on useEffect of the main entry component
export const registerToDownloadEvent = (dispatch: Dispatch) => {
  window.electron?.on(
    MainEvent.DOWNLOAD_BEGIN,
    (data: { id: DocumentType; expectedBytes: number }) => {
      const task = tasksMap.get(data.id)
      if (!task) {
        console.error('(In download begin) Task not found for', data.id)
        return
      }

      const newTaskData = {
        id: data.id,
        state: 'DOWNLOADING' as DownloadStatus,
        totalBytes: data.expectedBytes,
        createdAt: Date.now(),
      }

      tasksMap.set(data.id, {
        ...task,
        ...newTaskData,
      })

      dispatch(updateDownloadStatus(newTaskData))
    },
  )

  window.electron?.on(
    MainEvent.DOWNLOAD_PROGRESS,
    (data: { id: DocumentType; percent: number; bytesWritten: number; totalBytes: number }) => {
      const task = tasksMap.get(data.id)
      if (!task) {
        console.error('(In download in progress) Task not found for', data.id)
        return
      }
      const newTaskData = {
        ...task,
        ...data,
      }
      tasksMap.set(data.id, newTaskData)
      dispatch(updateDownloadStatus(data))
    },
  )

  window.electron?.on(MainEvent.DOWNLOAD_COMPLETE, (data: DownloadTaskData) => {
    const task = tasksMap.get(data.id)
    if (!task) {
      console.error('(In download complete) Task not found for', data.id)
      return
    }

    setTimeout(async () => {
      cleanupDataWhenFinishDownload(data)
      const taskObj = {
        ...task,
        state: 'DONE' as DownloadStatus,
        percent: 1,
        downloadFinishedAt: Date.now(),
      }
      await storeCompletedDownload(taskObj.id, taskObj)
      dispatch(updateDownloadStatus(taskObj))

      tasksMap.delete(data.id)
    }, 1000)
  })

  window.electron?.on(MainEvent.DOWNLOAD_FAILED, (data: { id: DocumentType; error: any }) => {
    const task = tasksMap.get(data.id)
    if (!task) {
      console.error('(In download failed) Task not found for', data.id)
      return
    }
    tasksMap.delete(data.id)
    dispatch(overrideDownloadStatus({ [data.id]: null }))
  })
}

export const getDownloadData = () => {
  return Array.from(tasksMap.values())
}

export const stopAllDownload = () => {
  window.electron?.sendMessage(RendererEvent.STOP_ALL_DOWNLOAD)
}

export const backgroundDownload = (data: DownloadTaskData) => {
  const newTask: DownloadInfo = {
    ...INIT_DOWNLOAD_DATA,
    id: data.id as DocumentType,
    state: 'DOWNLOADING' as DownloadStatus,
  }

  tasksMap.set(data.id, newTask)
  window.electron?.sendMessage(RendererEvent.DOWNLOAD_FILE, data)
  return newTask
}
