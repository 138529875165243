import { getProductDetailDbById } from 'database/queries/product'
import { getProductDetailQuery } from 'services'
import { ProductCompareType, ProductDetailResponseType } from 'types'
import { PAGE_IDS } from 'config/constants'
import { getEquipmentTable, getTechDataTable } from 'config/utils/ComparisonFunction'
import {
  ComparisonData,
  productCompareStyle,
  toProductComparisonHTML,
} from '../ProductComparison/ProductComparisonPDF'
import { getBreadcrumbData } from '../pdfHelper'
import { logoHTML, toBreadcrumbHTML } from '../pdfLayouts'
import { Config } from './type'
import { asyncFetchData } from './utils'

export const getProductCompareHTML = async (ids: ProductCompareType[], config: Config) => {
  try {
    const { isAppOnline, translations } = config

    if (!ids || !ids.length) return ''

    const productDetailResponses = await Promise.all(
      ids.map(async (product) => {
        const productDetailResponse = await asyncFetchData<number, ProductDetailResponseType>(
          isAppOnline,
          getProductDetailQuery,
          getProductDetailDbById,
          Number(product.id),
          Number(product.id),
        )
        return productDetailResponse
      }),
    )

    const productCompareDetails = productDetailResponses.map((response) => {
      if (response?.data) {
        return JSON.parse(response.data)
      } else {
        throw new Error('Error fetching product details')
      }
    })
    const compareListTechdata = productCompareDetails.map((item) => item.techdata)
    const equipmentsCompareData = productCompareDetails.map((item) => item.equipment)
    const techDataTable = getTechDataTable(compareListTechdata)
    const equipmentsTable = getEquipmentTable(equipmentsCompareData)

    const comparisonData: ComparisonData = {
      products: productCompareDetails.filter(Boolean),
      techLabels: techDataTable.map((item) => item.map((i) => i.value)),
      equipmentLabels: equipmentsTable.map((item) => item.map((i) => i.value)),
    }

    const breadCrumbData = getBreadcrumbData(PAGE_IDS.COMPARISON_SCREEN, config.structure)
    const breadCrumbHTML = toBreadcrumbHTML(breadCrumbData)

    // TODO: handle fetch data
    const mainHTML = toProductComparisonHTML(comparisonData, translations)

    return `
      <style>
      ${productCompareStyle}
      </style>
      ${logoHTML}
      ${breadCrumbHTML}
      ${mainHTML}
    `
  } catch (error) {
    console.error('getProductCompareHTML', error)
    return ''
  }
}
