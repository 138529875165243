import { useEffect } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { ContentRibbonType, PageContentResponseType } from 'types'
import { getContentRibbon } from 'config/utils/CommonFunction'
import { selectTranslations } from 'redux/selector'
import { handleSelectedMark } from 'features/Marking/markingFunctions'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'
import { usePDFLoading } from 'features/NewPDF/hooks/usePDFLoading'
import {
  APP_DYNAMIC_RIBBON_ID,
  APP_RIBBON_ID,
  useHeaderContext,
} from 'components/Page/headerContext'
import './contentRibbonStyles.scss'

type Props = {
  layoutData: ContentRibbonType
  onExport: (onDone: () => void) => void
  pageData?: PageContentResponseType
}

const ContentRibbon = ({ layoutData, onExport, pageData }: Props) => {
  const translations = useSelector(selectTranslations)
  const exportText = translations.export ?? 'EXPORT'
  const { showModal, hideModal } = useGlobalModalContext()
  const { headerHeight, ribbonRef, updateRibbonHeight } = useHeaderContext()

  const { headline, text } = layoutData
  const { pageId } = pageData || {}
  const { subHeadline, description } = getContentRibbon(text ?? '')
  const { pdfLoading, handleExportPDF } = usePDFLoading(onExport)

  /** Init Marking listener */
  useEffect(() => {
    document.addEventListener('mouseup', handleSelectedMark)
    return () => {
      document.removeEventListener('mouseup', handleSelectedMark)
    }
  }, [])

  useEffect(() => {
    updateRibbonHeight()
  }, [])

  const onShowModalFavorite = () => {
    showModal('FAVORITES_MODAL', {
      showModal: true,
      itemName: headline,
      pageContent: pageData,
      handleClose: hideModal,
    })
  }

  const renderStaticRibbon = () => (
    <Row className='align-items-center static-header ms-0'>
      <Col md='9' className='p-0'>
        {headline && (
          <h1
            id={`content-ribbon-headline-${pageId}`}
            className='heading m-0'
            dangerouslySetInnerHTML={{
              __html: headline,
            }}
          />
        )}
      </Col>
      <Col className='text-end p-0' md='3'>
        <i
          onClick={onShowModalFavorite}
          role='button'
          tabIndex={0}
          className='karcher-icon fav-icon icon-a_068_feedback_line me-2'
        >
          &#xe843;
        </i>

        {pdfLoading ? (
          <Spinner style={{ width: 12, height: 12 }} />
        ) : (
          <i
            onClick={handleExportPDF}
            role='button'
            tabIndex={0}
            title={exportText}
            className='karcher-icon export-icon icon-a_068_feedback_line ml-4'
          >
            &#xe850;
          </i>
        )}
      </Col>
    </Row>
  )

  const renderDynamicRibbon = () => (
    <Row ref={ribbonRef} id={APP_DYNAMIC_RIBBON_ID} className={'dynamic-header ms-0'}>
      <Col md='9' className='p-0'>
        {subHeadline && (
          <h6
            id={`content-ribbon-subHeadline-${pageId}`}
            className='ak-bold mt-2 mb-0'
            dangerouslySetInnerHTML={{
              __html: subHeadline,
            }}
          />
        )}
        {description && (
          <p
            id={`content-ribbon-description-${pageId}`}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
             className='mt-2 mb-0'
          />
        )}
      </Col>
    </Row>
  )

  return (
    <Container
      className='content-ribbon-wrapper'
      id={APP_RIBBON_ID}
      style={{ top: headerHeight }}
      fluid
    >
      <Container>
        {renderStaticRibbon()}
        {renderDynamicRibbon()}
      </Container>
    </Container>
  )
}

export default ContentRibbon
