import { Container } from 'react-bootstrap'
import Breadcrumb from 'components/Breadcrumb/Breadcrumb'
import Header from 'components/Header/Header'
import {
  APP_CONTENT_ID,
  APP_HEADER_ID,
  HeaderProvider,
  useHeaderContext,
} from 'components/Page/headerContext'
import ScrollToTopButton from 'components/ScrollToTopButton/ScrollToTopButton'

type PageProps = {
  pageId: number
  children: React.ReactNode
  className?: string
  breadCrumbTitle?: string
}

const Page = ({ pageId, children, breadCrumbTitle, className = '' }: PageProps) => {
  return (
    <HeaderProvider>
      <Container className={`${className} px-0`} fluid>
        <div id={APP_HEADER_ID}>
          <Header pageId={pageId} />
          <Breadcrumb pageId={pageId} staticTitle={breadCrumbTitle} />
        </div>

        <PageContent>{children}</PageContent>
      </Container>
      <ScrollToTopButton />
    </HeaderProvider>
  )
}

const PageContent = ({ children }: { children: React.ReactNode }) => {
  const { headerHeight, ribbonHeight } = useHeaderContext()
  return (
    <div id={APP_CONTENT_ID} style={{ paddingTop: headerHeight + ribbonHeight }}>
      {children}
      <div style={{ height: 20 }} />
    </div>
  )
}

export default Page
