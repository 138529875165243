import React, { useEffect } from 'react'
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { selectTranslations } from 'redux/selector'
import useNavigation from 'hooks/useNavigation'
import { handleSelectedMark } from 'features/Marking/markingFunctions'
import { usePDFLoading } from 'features/NewPDF/hooks/usePDFLoading'
import {
  APP_DYNAMIC_RIBBON_ID,
  APP_RIBBON_ID,
  useHeaderContext,
} from 'components/Page/headerContext'
import { ProductsNavType } from '../screens/ProductDetail/ProductDetail'
import 'styles/components/ribbon.scss'

type Props = {
  pageId?: number
  headline: string
  subHeadline?: JSX.Element | string
  description?: string
  targetId?: number
  productsNav?: ProductsNavType
  onAddFavorite: () => void
  onExportPDF?: (onDone: () => void) => void
}

const Ribbon: React.FC<Props> = ({
  pageId,
  targetId,
  headline,
  subHeadline,
  description,
  productsNav,
  onAddFavorite,
  onExportPDF,
}: Props) => {
  const translations = useSelector(selectTranslations)
  const { pdfLoading, handleExportPDF } = usePDFLoading(onExportPDF)
  const { updateRibbonHeight, headerHeight, ribbonRef } = useHeaderContext()

  useEffect(() => {
    updateRibbonHeight()
  }, [])

  const { onDynamicNavigate } = useNavigation()

  const exportText = translations.export || 'EXPORT'
  const moreInformationText = translations.more_information || 'MORE INFORMATION'

  /** Init Marking listener */
  useEffect(() => {
    document.addEventListener('mouseup', handleSelectedMark)

    return () => {
      document.removeEventListener('mouseup', handleSelectedMark)
    }
  }, [])

  const onGoToPage = (nextPageId?: number) => {
    if (!nextPageId) return

    onDynamicNavigate(nextPageId)
  }

  const onGoMoreInformation = () => {
    if (!targetId) return

    onDynamicNavigate(targetId)
  }

  const renderButtonLeft = () => {
    if (!productsNav || !productsNav.prev) return null

    return (
      <Button onClick={() => onGoToPage(productsNav.prev)} className='btn-left' variant='link'>
        <FontAwesomeIcon icon={faChevronLeft} className='icon' />
      </Button>
    )
  }

  const renderButtonRight = () => {
    if (!productsNav || !productsNav.next) return null

    return (
      <Button onClick={() => onGoToPage(productsNav.next)} className='btn-right' variant='link'>
        <FontAwesomeIcon icon={faChevronRight} className='icon' />
      </Button>
    )
  }

  const renderStaticRibbon = () => (
    <Row className='align-items-center static-header ms-0'>
      <Col md='9' className='p-0'>
        <h1
          id={`ribbon-headline-${pageId}`}
          dangerouslySetInnerHTML={{
            __html: headline,
          }}
          className='m-0'
        />
      </Col>
      <Col className='text-end p-0' md='3'>
        <i
          onClick={onAddFavorite}
          role='button'
          tabIndex={0}
          className='karcher-icon fav-icon icon-a_068_feedback_line me-2'
        >
          &#xe843;
        </i>

        {onExportPDF &&
          (pdfLoading ? (
            <Spinner style={{ width: 12, height: 12 }} />
          ) : (
            <i
              onClick={handleExportPDF}
              role='button'
              tabIndex={0}
              title={exportText}
              className='karcher-icon export-icon icon-a_068_feedback_line ml-4'
            >
              &#xe850;
            </i>
          ))}
      </Col>
    </Row>
  )

  const renderDynamicRibbon = () => (
    <Row ref={ribbonRef} id={APP_DYNAMIC_RIBBON_ID} className={'dynamic-header ms-0'}>
      <Col md='9' className='p-0'>
        {subHeadline && (
          <h6 id={`ribbon-sub-headline-${pageId}`} className='ak-bold mt-2 mb-0'>
            {subHeadline}
          </h6>
        )}
        {description && (
          <p
            id={`ribbon-description-${pageId}`}
            dangerouslySetInnerHTML={{
              __html: description || '',
            }}
            className='mt-2 mb-0'
          />
        )}
      </Col>
      {targetId && (
        <Col className='d-flex justify-content-end align-items-center p-0' md='3'>
          <Button
            id='onboarding-btn-more-info'
            className='btn_more_info'
            onClick={onGoMoreInformation}
          >
            {moreInformationText}
          </Button>
        </Col>
      )}
    </Row>
  )

  return (
    <Container className='ribbon-wrapper' id={APP_RIBBON_ID} style={{ top: headerHeight }} fluid>
      <Container>
        {renderButtonLeft()}
        {renderStaticRibbon()}
        {renderDynamicRibbon()}
        {renderButtonRight()}
      </Container>
    </Container>
  )
}

export default Ribbon
