import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ContentAnchorItemType } from 'types'
import { useHeaderContext } from 'components/Page/headerContext'
import 'styles/components/anchor.scss'

type AnchorProps = {
  anchorsData: ContentAnchorItemType[]
  anchorRef?: React.RefObject<HTMLDivElement>
}

const ContentAnchor = (props: AnchorProps): React.ReactElement => {
  const { anchorsData, anchorRef } = props
  const { headerHeight, ribbonHeight, dynamicRibbonHeight } = useHeaderContext()
  const [activeAnchor, setActiveAnchor] = useState<number | null>(null)
  const extraHeight = headerHeight + ribbonHeight - dynamicRibbonHeight

  const scrollListenerActive = useRef(true)

  /**
   * This effect will run when the component mounts and will create an IntersectionObserver
   * to observe the section elements inside the product section.
   * The observer will call the callback function when the section element is intersecting
   * with the root element (product section) and will set the active anchor.
   * The observer will disconnect when the component unmounts.
   */
  useEffect(() => {
    if (!anchorRef?.current) return

    const observer = new IntersectionObserver(
      (entries) => {
        if (!scrollListenerActive.current) return
        const activeEntry = entries.find((entry) => entry.isIntersecting)
        if (activeEntry && activeEntry.isIntersecting) {
          const activeAnchorIndex = anchorsData.findIndex(
            (anchor) => anchor.targetId === activeEntry.target.getAttribute('id'),
          )
          setActiveAnchor(activeAnchorIndex)
        }
      },
      { threshold: 0.8 },
    )

    anchorsData.forEach((anchor) => {
      const element = document.getElementById(anchor.targetId)
      if (element) observer.observe(element)
    })

    return () => {
      anchorsData.forEach((anchor) => {
        const element = document.getElementById(anchor.targetId)
        if (element) observer.unobserve(element)
      })
    }
  }, [anchorsData])

  const enableScrollListener = useCallback(
    debounce(() => {
      scrollListenerActive.current = true
    }, 1000),
    [],
  )

  const tempDisableScrollListener = () => {
    scrollListenerActive.current = false
    enableScrollListener()
  }

  const scrollToOffset = (topOffset: number) => {
    tempDisableScrollListener()
    window.scrollTo({ top: topOffset, behavior: 'smooth' })
  }

  const changeFocus = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number,
    targetId: string,
  ) => {
    e.preventDefault()
    setActiveAnchor(index)

    if (index === 0) {
      return scrollToOffset(0)
    }

    const element = document.getElementById(targetId)
    if (element) {
      const elementPosition = element.offsetTop
      const anchorHeight = document.getElementById('content-anchor-id')?.offsetHeight || 0
      const offsetPosition = elementPosition - extraHeight - anchorHeight
      scrollToOffset(offsetPosition)
    }
  }

  const renderItem = (anchor: { targetId: string; title: string }, index: number) => {
    const itemClassName = activeAnchor === index ? 'anchor-active-item' : 'anchor-item'
    return (
      <Col className={`${itemClassName} cursor-pointer`} key={anchor.targetId}>
        <a onClick={(e) => changeFocus(e, index, anchor.targetId)} className='p-3'>
          {typeof anchor.title === 'string' ? anchor.title.toUpperCase() : ''}
        </a>
      </Col>
    )
  }

  return (
    <Container
      style={{
        position: 'sticky',
        top: extraHeight,
        zIndex: 1001,
      }}
      id='content-anchor-id'
    >
      <Row className='anchor-wrapper ms-0'>
        {anchorsData.map((anchor, index) => renderItem(anchor, index))}
      </Row>
    </Container>
  )
}

export default ContentAnchor
