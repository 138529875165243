import { getProductDetailDbById } from 'database/queries/product'
import { getProductDetailQuery } from 'services'
import { ProductDetailResponseType } from 'types'
import useDataFetcher from 'hooks/useDataFetcher'

export const useProduct = () => {
  const { fetchData } = useDataFetcher()

  const fetchProductDetail = async (id: number) => {
    const response = await fetchData<number, ProductDetailResponseType>(
      getProductDetailQuery,
      getProductDetailDbById,
      id,
      id,
    )
    if (response?.data) {
      return JSON.parse(response.data)
    } else {
      throw new Error('Error fetching product details')
    }
  }

  const onGetProductDetails = async (productIds: number[]) => {
    const productDetails = await Promise.all(productIds.map((id) => fetchProductDetail(id)))
    return productDetails.filter(Boolean)
  }

  return {
    fetchProductDetail,
    onGetProductDetails,
  }
}
