import { getUserNotifications } from 'services/userNotifications'
import { PopUpDataType } from 'types/userNotificationsType'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'

const useUserNotifications = () => {
  const { showModal, hideModal } = useGlobalModalContext()
  const { pathname } = useLocation()

  const fetchPopUpInfo = async (): Promise<PopUpDataType | undefined> => {
    try {
      const popupIds: number[] = JSON.parse(localStorage.getItem('popupIds') ?? '[]')

      const response = await getUserNotifications()

      if (!response) return

      const { popUpInfo } = response

      if (!popUpInfo || !popUpInfo.data) return

      if (popupIds.includes(popUpInfo.id)) return
      showModal('POPUP_MODAL', {
        handleClose: hideModal,
        showModal: true,
        popUpData: response.popUpInfo.data,
      })

      localStorage.setItem('popupIds', JSON.stringify([...popupIds, popUpInfo.id]))
    } catch (error) {
      console.error('Error getUserNotifications:', error)
    }
  }

  useEffect(() => {
    fetchPopUpInfo()
  }, [pathname])

  return { fetchPopUpInfo }
}

export default useUserNotifications
