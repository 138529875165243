import throttle from 'lodash/throttle'
import React, { useEffect, useRef, useState } from 'react'
import { faArrowAltCircleUp } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './scrollToTopButton.scss'

const BEHAVIOR = {
  up: 0,
  down: 1,
}

const ANIMATION_CONFIG = {
  duration: 200,
  upThreshold: 100,
  downThreshold: 100,
}

const ScrollToTopButton: React.FC = () => {
  const scrollYRef = useRef(0)
  const scrollYTolerance = useRef(0)
  const [behavior, setBehavior] = useState(BEHAVIOR.down)
  const buttonRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleScroll = throttle(() => {
      const previousScrollY = scrollYRef.current
      const currentScrollY = window.scrollY
      scrollYRef.current = window.scrollY

      if (currentScrollY < ANIMATION_CONFIG.upThreshold) {
        scrollYTolerance.current = currentScrollY
        setBehavior(BEHAVIOR.down)
        return
      }

      if (currentScrollY > previousScrollY) {
        if (currentScrollY > scrollYTolerance.current + ANIMATION_CONFIG.downThreshold) {
          scrollYTolerance.current = currentScrollY
          setBehavior(BEHAVIOR.down)
        }
      } else if (currentScrollY < previousScrollY) {
        scrollYTolerance.current = currentScrollY
        setBehavior(BEHAVIOR.up)
      }
      // detect if the user has reached the bottom of the page
      if (
        window.scrollY + window.innerHeight + ANIMATION_CONFIG.downThreshold >=
        document.body.scrollHeight
      ) {
        setBehavior(BEHAVIOR.up)
      }
    }, 150)

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (behavior === BEHAVIOR.up) {
      buttonRef.current?.style.setProperty('opacity', '1')
    } else {
      buttonRef.current?.style.setProperty('opacity', '0')
    }
  }, [behavior])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    })
  }

  return (
    <div className='button-container cursor-pointer' ref={buttonRef}>
      <FontAwesomeIcon icon={faArrowAltCircleUp} onClick={scrollToTop} size='3x' />
    </div>
  )
}

export default ScrollToTopButton
