import { useState } from 'react'
import { Card, Col, FormCheck, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { ProductListRootLineItemType } from 'types'
import { productCompareAnalytics } from 'config/adobeAnalytics/analytics'
import { getPageContentWithCallback } from 'redux/reducers/pageContentReducer'
import { selectTranslations } from 'redux/selector'
import { selectProductComparison } from 'redux/selector/comparisonSelector'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'
import DCAImage from 'components/DCAImage'

type PropTypes = {
  product: ProductListRootLineItemType
  onGoNextPage: (pageId: number) => void
  onAddProductToComparison: (
    event: React.MouseEvent<HTMLDivElement>,
    productData: ProductListRootLineItemType,
  ) => void
}

const ProductGroupListItem = ({ product, onGoNextPage, onAddProductToComparison }: PropTypes) => {
  const dispatch = useDispatch()
  const { showModal, hideModal } = useGlobalModalContext()
  const { id, name, images, texts, priceGrossFormatted, priceNetFormatted, partnumberFormatted } =
    product
  const translations = useSelector(selectTranslations)
  const productCompare = useSelector(selectProductComparison)
  const imageUrl = images[0]?.urls?.find((url) => url.type === 'full') ?? { url: '' }
  const isCompare = !!productCompare.find((product) => product.id === Number(id))
  const orderNumber = translations.order_number ? translations.order_number : 'Part Number'
  const grossPriceNumber = translations.gross_priceNumber
    ? translations.gross_priceNumber
    : 'Price Gross'
  const netPriceNumber = translations.net_priceNumber ? translations.net_priceNumber : 'Price Net'

  const [favoriteIconLoading, setLoading] = useState(false)

  const onAddProductToFavorite = ({ id, name }: { id: number; name: string }) => {
    setLoading(true)
    dispatch(
      getPageContentWithCallback({
        pageId: id,
        async onSuccess(pageContent) {
          setLoading(false)
          showModal('FAVORITES_MODAL', {
            showModal: true,
            itemName: name,
            pageContent: pageContent,
            handleClose: hideModal,
          })
        },
        onFailure() {
          setLoading(false)
        },
      }),
    )
  }

  return (
    <Col md='3'>
      <Card className='card-wrapper' onClick={() => onGoNextPage(id)} style={{ marginBottom: 40 }}>
        <div
          onClick={(event) => {
            onAddProductToComparison(event, product)
            productCompareAnalytics(productCompare)
          }}
          className='cursor-pointer'
        >
          <FormCheck checked={isCompare} label={translations.compare || 'Compare'} readOnly />
        </div>

        <span
          tabIndex={0}
          role='button'
          className='favorite-form'
          onClick={(event) => {
            onAddProductToFavorite({ id, name })
            event.stopPropagation()
          }}
        >
          <div className='horizontal-p'>
            {favoriteIconLoading ? (
              <Spinner animation='border' role='status' className='favorite-icon' />
            ) : (
              <p className='karcher-icon favorite-icon'>&#xe843;</p>
            )}
            <p className='favorite-text'>{translations.add_to_fav ?? 'Add to favourites'}</p>
          </div>
        </span>

        <DCAImage
          type='cardImage'
          variant='top'
          src={imageUrl?.url}
          style={{ objectFit: 'contain' }}
        />
        <div className='text-left pt-3'>
          <p className='left-p'>{name}</p>

          <div className='product-price'>
            {orderNumber} {`${partnumberFormatted}`}
          </div>

          {priceNetFormatted && (
            <div className='product-price'>
              {netPriceNumber} {`${priceNetFormatted}`}
            </div>
          )}

          {priceGrossFormatted && (
            <div className='product-price'>
              {grossPriceNumber} {`${priceGrossFormatted}`}
            </div>
          )}

          {texts && <div className='product-desc'>{texts[0]?.value}</div>}
        </div>
      </Card>
    </Col>
  )
}
export default ProductGroupListItem
