import { Table, Transaction } from 'dexie'
import { v4 as uuidv4 } from 'uuid'
import { ALL_FOLDER, MY_FAVORITES_FOLDER } from 'config/constants'
import { universalSchema } from '../dbSchema'
import { UniversalSubTables } from '../dbTypes'

/**
 * Changes:
 * 1. Add field 'metadata' to the IDcaFavContent table
 * 2. Convert the IDcaFavContent table to have a new ID using uuidv4
 * 3. Convert the IDcaFavFolder table to have a new ID using uuidv4
 * 4. Convert the IDcaProductCompare table to have a new ID using uuidv4
 * 5. Convert the IDcaMarking table to have a new ID using uuidv4
 */
export const universalSchemaVer5 = {
  ...universalSchema,
  dcaFavContent: 'id++, folderID, pageId, title, type, country, language, year, position, metadata',
  dcaFolder: 'id, parentID, folderName, createdAt',
}

const isDefaultFolder = (id: string) => {
  const newId = id.toString()
  return newId === MY_FAVORITES_FOLDER.id || newId === ALL_FOLDER.id
}

const generateFolderIdsMap = (records: UniversalSubTables['IDcaFavFolder'][]) => {
  const folderIdsMap = new Map<string, string>()
  for (const record of records) {
    if (!record.id) continue
    const folderId = isDefaultFolder(record.id) ? record.id.toString() : uuidv4()
    folderIdsMap.set(record.id, folderId)
  }
  return folderIdsMap
}

const prepareFolderData = (
  records: UniversalSubTables['IDcaFavFolder'][],
  folderIdsMap: Map<string, string>,
) => {
  return records.map((folder) => ({
    ...folder,
    id: folderIdsMap.get(folder.id) ?? '0',
    parentID: folderIdsMap.get(folder.parentID) ?? '0',
    createdAt: new Date().toISOString(),
  }))
}

const prepareContentData = (
  records: UniversalSubTables['IDcaFavContent'][],
  folderIdsMap: Map<string, string>,
) => {
  return records.map((content) => ({
    ...content,
    id: uuidv4(),
    folderID: folderIdsMap.get(content.folderID) ?? '0',
    metadata: '',
  }))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const modifyTableRecords = async (table: Table<any>) => {
  await table.toCollection().modify((record) => {
    if (!record.id) return
    record.id = uuidv4()
  })
}

export async function migrateDBToVer5(trans: Transaction) {
  try {
    const favFolderTable: Table<UniversalSubTables['IDcaFavFolder']> = trans.table('dcaFavFolder')
    const favFolderRecords = await favFolderTable.toArray()

    const favContentTable: Table<UniversalSubTables['IDcaFavContent']> =
      trans.table('dcaFavContent')
    const favContentRecords = await favContentTable.toArray()

    const folderIdsMap = generateFolderIdsMap(favFolderRecords)

    await favFolderTable.clear()
    await favFolderTable.bulkAdd(prepareFolderData(favFolderRecords, folderIdsMap))

    await favContentTable.clear()
    await favContentTable.bulkAdd(prepareContentData(favContentRecords, folderIdsMap))

    await modifyTableRecords(trans.table('dcaProductCompare'))
    await modifyTableRecords(trans.table('dcaMarking'))
  } catch (error) {
    console.error('Error initializing app data', error)
  }
}
