import type { Identifier, XYCoord } from 'dnd-core'
import { useDrag, useDrop } from 'react-dnd'
import { FC, useRef, useState } from 'react'
import { Dropdown, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { FavoriteContentType } from 'types'
import { LAYOUTS, TRANSLATIONS } from 'config/constants'
import { getPageContentWithCallback } from 'redux/reducers/pageContentReducer'
import { selectTranslations } from 'redux/selector'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'
import { useFavorites } from '../hooks/useFavorites'
import './favorite.scss'

export const ItemTypes = {
  CARD: 'card',
}

export interface CardProps {
  index: number
  id: string
  data: FavoriteContentType
  moveItem: (dragIndex: number, hoverIndex: number) => void
  moveItemFinish: () => void
  onPressItem: (data: FavoriteContentType) => void
  onSavePDF: (data: FavoriteContentType[]) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

const FAV_ICONS_BY_TYPE = {
  [LAYOUTS.PRODUCT_GROUPS_LIST]: '&#xe920',
  [LAYOUTS.PRODUCT_DETAILS]: '&#xe917;',
  [LAYOUTS.PRODUCT_LIST]: '&#xe920',
  default: '&#xe83e',
}

export const FavoriteItem: FC<CardProps> = ({
  id,
  data,
  index,
  moveItem,
  moveItemFinish,
  onPressItem,
  onSavePDF,
}) => {
  const translations = useSelector(selectTranslations)
  const dispatch = useDispatch()
  const { deleteItemInFavorites } = useFavorites()
  const { showModal, hideModal } = useGlobalModalContext()
  const [loading, setLoading] = useState(false)

  const MENU_ITEMS = [
    {
      eventKey: 'edit',
      label: translations?.edit ?? TRANSLATIONS.edit,
    },
    {
      eventKey: 'copy',
      label: translations?.copy ?? TRANSLATIONS.copy,
    },
    {
      eventKey: 'delete',
      label: translations?.delete ?? TRANSLATIONS.delete,
    },
    {
      eventKey: 'export',
      label: translations?.export ?? TRANSLATIONS.export,
    },
  ]

  const ref = useRef<HTMLDivElement>(null)

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex

      moveItem(dragIndex, hoverIndex)
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        moveItemFinish()
      }
    },
  })

  const onItemClicked = () => onPressItem(data)

  const icon = FAV_ICONS_BY_TYPE[data.type] ?? FAV_ICONS_BY_TYPE.default

  drag(drop(ref))

  const handleDelete = (favContent: FavoriteContentType) => {
    deleteItemInFavorites(favContent.id)
  }

  const handleEdit = (favContent: FavoriteContentType) => {
    showModal('FAVORITES_MODAL', {
      showModal: true,
      itemName: favContent.title,
      favoritesContent: favContent,
      handleClose: hideModal,
    })
  }

  const handleCopy = (favContent: FavoriteContentType) => {
    setLoading(true)
    dispatch(
      getPageContentWithCallback({
        pageId: favContent.pageId,
        onSuccess(pageData) {
          setLoading(false)
          showModal('FAVORITES_MODAL', {
            showModal: true,
            itemName: favContent.title,
            metadata: favContent?.metadata || '',
            handleClose: hideModal,
            /**
             * @todo
             * update translations
             */
            popupTitle: 'Copy Favorite',
            pageContent: pageData,
          })
        },
        onFailure() {
          setLoading(false)
        },
      }),
    )
  }

  const onSelectDropdownItem = (eventKey: string | null) => {
    switch (eventKey) {
      case 'delete':
        handleDelete(data)
        break
      case 'copy':
        handleCopy(data)
        break

      case 'edit':
        handleEdit(data)
        break

      case 'export':
        onSavePDF([data])
        break
      default:
        break
    }
  }

  const style = {
    backgroundColor: index % 2 ? '#fff' : '#f2f2f2',
    opacity: isDragging ? 0 : 1,
  }

  return (
    <div ref={ref} className='favorite-container' style={style} data-handler-id={handlerId}>
      <div className='favorite-item-title-container' onClick={onItemClicked}>
        <p className='karcher-icon favorite-item-icon' dangerouslySetInnerHTML={{ __html: icon }} />
        <p className='favorite-item-title'>{data.title}</p>
      </div>
      <Dropdown onSelect={(eventKey) => onSelectDropdownItem(eventKey)}>
        <Dropdown.Toggle bsPrefix='toggleDropDown' childBsPrefix='none'>
          {loading ? (
            <Spinner style={{ width: 12, height: 12 }} />
          ) : (
            <i className='karcher-icon'> &#xe81f; </i>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className='dropdown-menu'>
          {MENU_ITEMS.map((item) => (
            <Dropdown.Item key={item.eventKey} eventKey={item.eventKey}>
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
