import { getProductDetailDbById, getProductListDbById } from 'database/queries/product'
import { getProductDetailQuery, getProductListQuery } from 'services'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ProductDetailItemType, ProductDetailResponseType, ProductListResponseType } from 'types'
import { clearProductAnalytics, productAnalytics } from 'config/adobeAnalytics/analytics'
import { LAYOUTS, PAGE_NAMES, PAGE_TYPES } from 'config/constants'
import { isObjectEmpty } from 'config/utils/CommonFunction'
import { RootState } from 'redux/reducers'
import { clearProductDetailFilterState } from 'redux/reducers/navigationReducer'
import { getPageContent } from 'redux/reducers/pageContentReducer'
import { selectPageContent } from 'redux/selector'
import useAnalytics from 'hooks/useAnalytics'
import useDataFetcher from 'hooks/useDataFetcher'
import usePreviousValue from 'hooks/usePreviousValue'
import ErrorPage from 'features/Common/screens/ErrorPage'
import LoadingPage from 'features/Common/screens/LoadingPage'
import Page from 'components/Page/Page'
import ProductDetailUI from './ProductDetail.ui'

export type ProductsNavType = {
  next?: number
  prev?: number
}

const ProductDetail = (): React.ReactElement => {
  const dispatch = useDispatch()

  const { pageId } = useParams<{ pageId: string }>()
  const pageIdNumber = Number(pageId)

  const previousPageId = usePreviousValue(pageIdNumber)
  const { setPageInfoAnalytics } = useAnalytics()
  const { fetchData } = useDataFetcher()

  const pageData = useSelector((state: RootState) => selectPageContent(state, pageIdNumber))
  const { data, error, loading } = pageData ?? {}

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)
  const [productDetails, setProductDetails] = useState<ProductDetailItemType>()
  const [productsNav, setProductsNav] = useState<ProductsNavType>({
    next: undefined,
    prev: undefined,
  })

  useEffect(() => {
    setPageInfoAnalytics({
      pageName: PAGE_NAMES.PRODUCT_DETAIL,
      pageType: PAGE_TYPES.PRODUCT_DETAIL,
      pageId: pageIdNumber,
    })
    clearProductAnalytics()
  }, [])

  useEffect(() => {
    return () => {
      dispatch(clearProductDetailFilterState())
    }
  }, [])

  useEffect(() => {
    if (!previousPageId || previousPageId !== pageIdNumber) {
      setIsLoading(true)
      dispatch(getPageContent(pageIdNumber))
    }
  }, [pageIdNumber, dispatch, previousPageId])

  // get page content error
  useEffect(() => {
    if (error) {
      setIsError(true)
      setIsLoading(false)
    }
  }, [error])
  const handleProductDetailNavigation = async (productDetailData: ProductDetailItemType) => {
    if (!productDetailData || isObjectEmpty(productDetailData)) return

    const productList = await fetchData<number, ProductListResponseType>(
      getProductListQuery,
      getProductListDbById,
      productDetailData.productgroupId,
      productDetailData.productgroupId,
    )

    if (!productList || !productList?.data) return
    const { data } = productList
    const currentProductIndex = data.findIndex(
      (product) => parseInt(product.id) === productDetailData.id,
    )
    setProductsNav({
      next: parseInt(data[currentProductIndex + 1]?.id) || undefined,
      prev: parseInt(data[currentProductIndex - 1]?.id) || undefined,
    })
  }

  const handleProductDetailData = async () => {
    try {
      const productContent = data?.content?.find((item) => item.type === LAYOUTS.PRODUCT_DETAILS)
      const config = JSON.parse(productContent?.config ?? '{}')
      if (config) {
        const productDetailResponse = await fetchData<number, ProductDetailResponseType>(
          getProductDetailQuery,
          getProductDetailDbById,
          config.productId,
          config.productId,
        )

        if (!productDetailResponse) {
          setIsError(true)
          return
        }

        const { data } = productDetailResponse
        const productDetailData: ProductDetailItemType = JSON.parse(data || '{}')
        if (productDetailData) {
          await handleProductDetailNavigation(productDetailData)
          setProductDetails(productDetailData)
        }
      }
    } catch (error) {
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (loading === false && data) handleProductDetailData()
  }, [data, loading])

  const renderContent = () => {
    if (isLoading) return <LoadingPage />

    if (isError) return <ErrorPage />

    if (data && productDetails && !isObjectEmpty(productDetails)) {
      productAnalytics({
        sku: productDetails.id.toString(),
        ean: productDetails.ean,
        name: productDetails.name,
        type: productDetails.productType,
      })
      return (
        <ProductDetailUI data={data} productDetails={productDetails} productsNav={productsNav} />
      )
    }

    return null
  }

  return (
    <Page pageId={pageIdNumber} className='product-detail-container'>
      {renderContent()}
    </Page>
  )
}

export default ProductDetail
