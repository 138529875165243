import { getCurrentCountry, getCurrentLanguage } from 'config/utils/CommonFunction'

export const ZOOVU_EVENT_NAMES = {
  SELECT_CATEGORY: 'select-category',
  SELECT_PRODUCT: 'select-product-detail',
  ADD_TO_COMPARE: 'add-to-comparison',
  REMOVE_FROM_COMPARE: 'remove-to-comparison',
}

const ZOOVU_PROD_CODE = 'bsgpcBNt'
const ZOOVU_STAGE_CODE = 'SCPTNpkQD8TJ'

export const getDefaultZoovuCode = () => {
  if (process.env.REACT_APP_DEPLOYMENT_ENV === 'prod') {
    return ZOOVU_PROD_CODE
  }
  return ZOOVU_STAGE_CODE
}

const MAP_COUNTRY: Record<string, string> = {
  DCF: 'IN',
  DCS: 'BM',
}

export const getZoovuLocale = async () => {
  const currentCountry = await getCurrentCountry()
  const countryCode = currentCountry?.code?.toLocaleUpperCase() || ''
  const zoovuCountry = MAP_COUNTRY[countryCode] ?? countryCode

  const currentLanguage = await getCurrentLanguage()
  const languageCode = currentLanguage?.languageCode || ''

  return `${languageCode}-${zoovuCountry}`
}

export const toZoovuUrl = (code: string, local: string, ids: string) => {
  return `https://orca-api.zoovu.com/advisor-fe-web/api/v1/advisors/${code}/iframe-loader?locale=${local}&isWebApp=true&comparisonBasket=${ids}`
}
