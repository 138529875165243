import { getBusinessHeadlineDb } from 'database/queries/country'
import Joyride, { ACTIONS, CallBackProps, EVENTS } from 'react-joyride'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { PAGE_IDS } from 'config/constants'
import { AppDataKeyEnum, GET_APP_DATA } from 'config/libs/globals'
import { selectCurrentCountry, selectTranslations } from 'redux/selector'
import { useAppContext } from 'context/AppContext'
import useAnalytics from 'hooks/useAnalytics'
import useNavigation from 'hooks/useNavigation'
import { HeadlineDataType } from 'components/Header/Header'
import {
  StepEight,
  StepEleven,
  StepFive,
  StepFour,
  StepNine,
  StepOne,
  StepSeven,
  StepSix,
  StepTen,
  StepThree,
  StepTwo,
} from '../components/StepContent'
import useOnboardingTour from '../hooks/useOnboarding'
import 'styles/pages/onboarding.scss'

const customStyles = {
  options: {
    zIndex: 2000,
  },
  buttonNext: {
    backgroundColor: 'yellow',
    borderRadius: '0px',
    color: 'black',
    padding: '10px 16px',
    fontSize: '12px',
    fontFamily: 'Clan Pro Medium',
  },
  buttonBack: {
    backgroundColor: '#e3e3e3',
    borderRadius: '0px',
    color: 'black',
    padding: '10px 16px',
    fontSize: '12px',
    fontFamily: 'Clan Pro Medium',
    marginRight: '10px',
  },
  buttonSkip: {
    backgroundColor: '#e3e3e3',
    borderRadius: '0px',
    color: 'black',
    padding: '10px 16px',
    fontSize: '12px',
    fontFamily: 'Clan Pro Medium',
  },
  buttonClose: {
    fontSize: '1.5rem',
  },
}

const OnboardingTour = () => {
  const translations = useSelector(selectTranslations)
  const currentCountry = useSelector(selectCurrentCountry)
  const { setOnboardingAnalytics } = useAnalytics()

  const { onGoBack, onDynamicNavigate } = useNavigation()
  const { loadOnboardingData } = useOnboardingTour()

  const skip = translations.skip ?? 'Skip'
  const next = translations.next ?? 'Next'
  const back = translations.back ?? 'Back'

  const {
    setState,
    state: { run, stepIndex, steps, onboardingData },
  } = useAppContext()

  useEffect(() => {
    setState({
      steps: [
        {
          content: <StepOne translations={translations} />,
          placement: 'center',
          target: 'body',
          showSkipButton: true,
        },
        {
          content: <StepTwo translations={translations} />,
          target: '#onboarding-logo',
        },
        {
          content: <StepThree translations={translations} />,
          target: '#onboarding-icon-status',
        },
        {
          content: <StepFour translations={translations} />,
          target: '#onboarding-icon-fav',
        },
        {
          content: <StepFive translations={translations} />,
          target: '#onboarding-icon-comparison',
        },
        {
          content: <StepSix translations={translations} />,
          target: '#onboarding-btn-more-info',
        },
        {
          content: <StepSeven translations={translations} />,
          target: '#onboarding-order-number',
        },
        {
          content: <StepEight translations={translations} />,
          target: '#onboarding-icon-export',
        },
        {
          content: <StepNine translations={translations} />,
          target: '#onboarding-btn-download',
        },
        {
          content: <StepTen translations={translations} />,
          target: '#onboarding-btn-change-country',
        },
        {
          content: <StepEleven translations={translations} />,
          target: '#onboarding-btn-show-onboarding',
        },
      ],
    })
  }, [translations])

  useEffect(() => {
    if (currentCountry) {
      loadOnboardingData()
    }
  }, [currentCountry])

  const handleCallback = async (data: CallBackProps) => {
    const { action, index, type } = data
    setOnboardingAnalytics({ step: index })

    switch (action) {
      case ACTIONS.NEXT:
        if (type === EVENTS.STEP_AFTER) {
          switch (index) {
            case 4:
              setState({ run: false })
              onDynamicNavigate(onboardingData.productGroupId)
              break
            case 5:
              setState({ run: false })
              onDynamicNavigate(onboardingData.productId)
              break
            case 7:
              setState({ run: false })
              onDynamicNavigate(PAGE_IDS.SETTINGS)
              break
            default:
              setState({ stepIndex: index + 1 })
              break
          }
        }
        break
      case ACTIONS.PREV:
        if (type === EVENTS.STEP_AFTER) {
          if ([5, 6, 8].includes(index)) {
            onGoBack()
            setState({ stepIndex: index - 1 })
          } else {
            setState({ stepIndex: index - 1 })
          }
        }
        break
      case ACTIONS.RESET:
      case ACTIONS.CLOSE:
      case ACTIONS.SKIP:
        setState({ run: false, stepIndex: 0, tourActive: false })
        break
      default:
        break
    }

    /** Last step */
    if (type === EVENTS.TOUR_END) {
      setState({ run: false, stepIndex: 0, tourActive: false })
      let headlineData = GET_APP_DATA(AppDataKeyEnum.businessHeadline) as HeadlineDataType
      if (!headlineData) {
        headlineData = (await getBusinessHeadlineDb()) as HeadlineDataType
      }
      onDynamicNavigate(headlineData?.pageId)
    }
  }

  return (
    <div className='onboarding-tour'>
      {run && (
        <Joyride
          steps={steps}
          run={run}
          continuous
          showProgress={true}
          showSkipButton={false}
          stepIndex={stepIndex}
          locale={{
            skip: skip,
            next: next,
            back: back,
          }}
          disableScrolling
          styles={{
            ...customStyles,
            options: {
              width: stepIndex === 0 ? 700 : 450,
              zIndex: 2000
            },
          }}
          floaterProps={{
            styles: {
              wrapper: {
                zIndex: 2000
              },
            },
          }}
          callback={handleCallback}
        />
      )}
    </div>
  )
}

export default OnboardingTour
