import { deleteFavFolderDb, getFavoritesDb } from 'database/queries/favorites'
import { Dropdown, FormControl, InputGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  faCaretDown,
  faCaretRight,
  faFolder,
  faFolderClosed,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FavoriteFolderType } from 'types'
import { ALL_FOLDER, MY_FAVORITES_FOLDER, TRANSLATIONS } from 'config/constants'
import { selectTranslations } from 'redux/selector'
import { useFavorites } from '../hooks/useFavorites'

interface FolderProps {
  isModal?: boolean
  folder: FavoriteFolderType
  folders: FavoriteFolderType[]
  expandedFolders: string[]
  activeFolderId?: string
  isCanCreateFolder: boolean
  onExpand: (folderId: string) => void
  onExportFolder: (folderId: string) => void
  onExportFolderShort: (folderId: string) => void
  onFolderNameChange: (folderName: string) => void
}

const FavoriteFolder = ({
  isModal,
  folder,
  folders,
  expandedFolders,
  activeFolderId,
  isCanCreateFolder,
  onExpand,
  onFolderNameChange,
  onExportFolder,
  onExportFolderShort,
}: FolderProps) => {
  const translations = useSelector(selectTranslations)
  const { onLoadFavorites } = useFavorites()

  const pleaseEnterFolderName = translations.enter_new_foldername ?? 'Please enter folder name'
  const exportTxt = translations.export ?? TRANSLATIONS.export
  const exportShortTxt = translations.export_short ?? TRANSLATIONS.exportShort
  const deleteTxt = translations.delete ?? TRANSLATIONS.delete

  if (!folder) return null

  const subfolders = folders.filter((f) => f.parentID === folder.id)
  const isExpanded = expandedFolders.includes(folder.id)

  const caretIcon = activeFolderId === folder.id ? faCaretDown : faCaretRight
  const folderIcon = activeFolderId === folder.id ? faFolder : faFolderClosed
  const folderColor = activeFolderId === folder.id ? 'black' : 'white'
  const isSubFolder = folder.id !== ALL_FOLDER.id && folder.id !== MY_FAVORITES_FOLDER.id

  const handleExpand = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!folder.id) return

    e.preventDefault()
    onExpand(folder.id)
  }

  const handleExport = async (eventKey: string | null) => {
    switch (eventKey) {
      case 'export':
        onExportFolder(folder.id)
        break
      case 'export-short':
        onExportFolderShort(folder.id)
        break
      case 'folder-delete':
        await deleteFavFolderDb(folder.id)
        onLoadFavorites()
        break
      default:
        return
    }
  }

  return (
    <div className='m-2 cursor-pointer'>
      <div className='d-flex flex-row'>
        <span onClick={handleExpand}>
          <FontAwesomeIcon icon={caretIcon} className='mx-2' />
          <FontAwesomeIcon
            icon={folderIcon}
            style={{ stroke: 'black', strokeWidth: 20 }}
            color={folderColor}
          />
          <span className='ak-bold mx-1'>&nbsp;{folder.folderName}</span>
        </span>

        {!isModal && (
          <Dropdown onSelect={handleExport}>
            <div>
              <Dropdown.Toggle bsPrefix='toggleDropDown' childBsPrefix='none'>
                <i className='karcher-icon'> &#xe81f; </i>
              </Dropdown.Toggle>
              <Dropdown.Menu className='dropdown-menu'>
                <Dropdown.Item key='export' eventKey='export'>
                  {exportTxt}
                </Dropdown.Item>
                <Dropdown.Item key='export-short' eventKey='export-short'>
                  {exportShortTxt}
                </Dropdown.Item>
                {isSubFolder && (
                  <Dropdown.Item key='folder-delete' eventKey='folder-delete'>
                    {deleteTxt}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </div>
          </Dropdown>
        )}
      </div>

      {isCanCreateFolder && activeFolderId === folder.id && (
        <InputGroup size='sm' className='fav-input-group'>
          <FormControl
            className='fav-form-control'
            onChange={(event) => onFolderNameChange(event.target.value)}
            defaultValue={''}
            placeholder={pleaseEnterFolderName}
          />
        </InputGroup>
      )}

      {isExpanded &&
        subfolders.map((subfolder) => (
          <FavoriteFolder
            key={subfolder.id}
            isModal={isModal}
            folder={subfolder}
            folders={folders}
            activeFolderId={activeFolderId}
            isCanCreateFolder={isCanCreateFolder}
            expandedFolders={expandedFolders}
            onExpand={onExpand}
            onExportFolder={onExportFolder}
            onExportFolderShort={onExportFolderShort}
            onFolderNameChange={onFolderNameChange}
          />
        ))}
    </div>
  )
}

export default FavoriteFolder
