import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'

export const APP_HEADER_ID = 'app-static-header'
export const APP_CONTENT_ID = 'app-content'
export const APP_RIBBON_ID = 'ribbon-wrapper'
export const APP_DYNAMIC_RIBBON_ID = 'dynamic-ribbon-wrapper'

type HeaderContextProps = {
  headerHeight: number
  ribbonHeight: number
  dynamicRibbonHeight: number
  updateRibbonHeight: () => void
  ribbonRef: React.RefObject<HTMLDivElement>
}
const HeaderContext = createContext<HeaderContextProps | undefined>(undefined)

type HeaderProviderProps = {
  children: React.ReactNode
}

export const HeaderProvider = ({ children }: HeaderProviderProps): React.ReactElement => {
  const [headerHeight, setHeaderHeight] = useState(0)
  const [ribbonHeight, setRibbonHeight] = useState(0)
  const [dynamicRibbonHeight, setDynamicRibbonHeight] = useState(0)
  const ribbonRef = useRef<HTMLDivElement>(null)

  // set the header height, and update it when window resize
  useEffect(() => {
    const updateHeaderHeight = () => {
      const header = document.getElementById(APP_HEADER_ID)
      if (header) {
        setHeaderHeight(header.offsetHeight)
      }
    }

    updateHeaderHeight()
    window.addEventListener('resize', updateHeaderHeight)
    return () => {
      window.removeEventListener('resize', updateHeaderHeight)
    }
  }, [])

  // listen when user scroll to set the height of dynamic ribbon
  useEffect(() => {
    const handleScroll = () =>
      requestAnimationFrame(() => {
        // update height
        const height = Math.max(dynamicRibbonHeight - window.scrollY, 0)
        ribbonRef.current?.style.setProperty('height', `${height}px`)
        // update opacity
        const opacity = Math.max(1 - window.scrollY / dynamicRibbonHeight, 0).toString()
        ribbonRef.current?.style.setProperty('opacity', opacity)
      })

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [dynamicRibbonHeight])

  // reset height of dynamic ribbon when window resize
  useEffect(() => {
    const setStyleForContent = () => {
      ribbonRef.current?.style.setProperty('height', 'auto')
      ribbonRef.current?.style.setProperty('opacity', '1')
      // recalculate new ribbon height when window resize changed
      updateRibbonHeight()

      // scroll down a bit to re-calculate the dynamic ribbon height
      setTimeout(() => {
        window.scrollBy(0, -1)
      }, 300)
    }

    window.addEventListener('resize', setStyleForContent)
    return () => {
      window.removeEventListener('resize', setStyleForContent)
    }
  }, [])

  // set the ribbon height when the ribbon is mounted
  const updateRibbonHeight = useCallback(() => {
    const ribbon = document.getElementById(APP_RIBBON_ID)
    if (ribbon) {
      setRibbonHeight(ribbon.offsetHeight)
    }
    const dynamicRibbon = document.getElementById(APP_DYNAMIC_RIBBON_ID)
    if (dynamicRibbon) {
      setDynamicRibbonHeight(dynamicRibbon.offsetHeight)
    }
  }, [])

  return (
    <HeaderContext.Provider
      value={{
        dynamicRibbonHeight,
        headerHeight,
        ribbonHeight: ribbonHeight,
        updateRibbonHeight,
        ribbonRef,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export const useHeaderContext = (): HeaderContextProps => {
  const context = useContext(HeaderContext)
  if (context === undefined) {
    throw new Error('useHeaderContext must be used within a HeaderProvider')
  }
  return context
}
