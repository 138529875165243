import { useState } from 'react'
import { Card, FormCheck, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { AccessoryItemType } from 'types'
import { productCompareAnalytics } from 'config/adobeAnalytics/analytics'
import { getPageContentWithCallback } from 'redux/reducers/pageContentReducer'
import { selectTranslations } from 'redux/selector'
import { selectProductComparison } from 'redux/selector/comparisonSelector'
import { useProductComparison } from 'features/Comparison/hooks/useProductComparison'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'
import DCAImage from 'components/DCAImage'
import 'styles/pages/productList.scss'

type AccessoryProps = {
  accessory: AccessoryItemType
  onGoNextPage: (pageId: number) => void
}

const Accessory = ({ accessory, onGoNextPage }: AccessoryProps) => {
  const translations = useSelector(selectTranslations)
  const productCompare = useSelector(selectProductComparison)
  const [favoriteIconLoading, setLoading] = useState(false)
  const { showModal, hideModal } = useGlobalModalContext()
  const dispatch = useDispatch()

  const { onAddOrRemoveProductCompare } = useProductComparison()

  const compareTitle = translations.compare ?? 'Compare'
  const isCompare = !!productCompare.find((product) => product.id === Number(accessory.id))

  const onGoProductDetail = () => {
    onGoNextPage(accessory.id)
  }

  const onAddProductToComparison = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    onAddOrRemoveProductCompare(accessory)
  }

  const onAddProductToFavorite = ({ id, name }: { id: number; name: string }) => {
    setLoading(true)
    dispatch(
      getPageContentWithCallback({
        pageId: id,
        async onSuccess(pageContent) {
          setLoading(false)
          showModal('FAVORITES_MODAL', {
            showModal: true,
            itemName: name,
            pageContent: pageContent,
            handleClose: hideModal,
          })
        },
        onFailure() {
          setLoading(false)
        },
      }),
    )
  }

  return (
    <Card className='accessory cursor-pointer' onClick={onGoProductDetail}>
      <div
        onClick={() => {
          onAddProductToComparison
          productCompareAnalytics(productCompare)
        }}
      >
        <FormCheck
          checked={isCompare}
          label={compareTitle}
          onClick={onAddProductToComparison}
          readOnly
        />
      </div>
      <span
        tabIndex={0}
        role='button'
        className='favorite-form'
        onClick={(event) => {
          onAddProductToFavorite({ id: parseInt(accessory.id.toString()), name: accessory.name })
          event.stopPropagation()
        }}
      >
        <div className='horizontal-p'>
          {favoriteIconLoading ? (
            <Spinner className='favorite-icon' />
          ) : (
            <p className='karcher-icon favorite-icon'>&#xe843;</p>
          )}
          <p className='favorite-text'>{translations.add_to_fav ?? 'Add to favourites'}</p>
        </div>
      </span>
      <DCAImage variant='top' type='cardImage' src={accessory.image} className='accessory-image' />
      <Card.Text className='accessory-name'>{accessory.name}</Card.Text>
    </Card>
  )
}

export default Accessory
