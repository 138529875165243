import images from 'assets/images/images'
import React from 'react'
import DCAImage from 'components/DCAImage'

const styles = {
  container: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    top: 0,
  },
  image: {
    height: 60,
    width: 60,
  },
}

const LoadingPage = (): React.ReactElement => (
  <div className='app-loading' style={styles.container as any}>
    <DCAImage alt='loading' src={images.loading} style={styles.image} />
  </div>
)

export default LoadingPage
