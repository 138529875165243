import { universal } from 'database/db'
import { IDcaFavContent } from 'database/dbTypes'
import { FavoriteContentType, FavoriteFolderType } from 'types'
import { sortByStringPropertyAscending } from 'config/libs/helpers'

const getSortedFavoriteContent = async (): Promise<FavoriteContentType[]> => {
  const favoriteContent = await universal.table('dcaFavContent').toArray()
  return sortByStringPropertyAscending(favoriteContent, 'position')
}

const getSortedFavoriteFolder = async (): Promise<FavoriteFolderType[]> => {
  const favoriteFolder = await universal.table('dcaFavFolder').toArray()
  return sortByStringPropertyAscending(favoriteFolder, 'createdAt')
}

export const getFavoritesDb = async (): Promise<{
  favoriteContent: FavoriteContentType[]
  favoriteFolder: FavoriteFolderType[]
}> => {
  try {
    const [favoriteContent, favoriteFolder] = await Promise.all([
      getSortedFavoriteContent(),
      getSortedFavoriteFolder(),
    ])
    return { favoriteContent, favoriteFolder }
  } catch (error) {
    console.error('Error getting favorite content from database:', error)
    throw error
  }
}

/** Update all the data in the favorites DB */
export const updatedFavoritesDb = (favoritesContent: FavoriteContentType[]) => {
  try {
    return universal.dcaFavContent.bulkPut(favoritesContent)
  } catch (error) {
    console.error('updatedFavoritesDb fail', error)
  }
}

export const saveFavContentDb = async (favoriteData: IDcaFavContent) => {
  try {
    const id = await universal.dcaFavContent.put(favoriteData)
    return { success: true, id }
  } catch (error) {
    console.error('saveFavContentDb Fail', error)
  }
}

export const editFavContentDb = async (id: string, favoriteData: FavoriteContentType) => {
  try {
    await universal.dcaFavContent
      .where('id')
      .equals(id)
      .modify({ ...favoriteData })
    return { success: true }
  } catch (error) {
    console.error('editFavContentDB Fail', error)
  }
}

export const deleteFavContentDb = async (id: string) => {
  try {
    await universal.dcaFavContent.delete(id)
    const favoriteContent = await universal.table('dcaFavContent').toArray()
    return { success: true, data: favoriteContent }
  } catch (error) {
    console.error('deleteFavContentDb fail', error)
  }
}

export const saveFavFolderDb = async (newFolder: FavoriteFolderType) => {
  try {
    await universal.dcaFavFolder.put(newFolder)
    return { success: true }
  } catch (error) {
    console.error('saveFavFolderDb fail', error)
    return { success: false }
  }
}

/**
 * Handle delete multiple folder:
 * 1. Delete main folder
 * 2. Delete all sub folders
 * 3. Delete all content in all folders
 */
const deleteFolderById = async (id: string) => {
  try {
    await universal.dcaFavFolder.where('id').equals(id).delete()
    return { success: true }
  } catch (error) {
    console.error('deleteFolderById fail', error)
    return { success: false }
  }
}

const getSubFolders = async (parentId: string) => {
  try {
    const subFolders = await universal.dcaFavFolder.where('parentID').equals(parentId).toArray()
    return subFolders
  } catch (error) {
    console.error('getSubFolders fail', error)
    return []
  }
}

const deleteFolderContents = async (folderId: string) => {
  try {
    await universal.dcaFavContent.where('folderID').equals(folderId).delete()
    return { success: true }
  } catch (error) {
    console.error('deleteFolderContents fail', error)
    return { success: false }
  }
}

export const deleteFavFolderDb = async (folderId: string) => {
  try {
    const subFolderIds = await getSubFolders(folderId)
    if (subFolderIds.length) {
      for (const subFolder of subFolderIds) {
        await deleteFavFolderDb(subFolder.id)
      }
    }

    await deleteFolderContents(folderId)
    await deleteFolderById(folderId)
    return { success: true }
  } catch (error) {
    console.error('deleteFavFolderDb fail', error)
    return { success: false }
  }
}
