import { RendererEvent } from 'electron-shared'
import { useEffect } from 'react'
import packageJson from '../../package.json'

export const useAppUpdate = () => {
  useEffect(() => {
    const checkAppUpdate = async () => {
      if (window.electron?.isDesktop()) {
        const desktopAppVersion = await window.electron.invoke(
          RendererEvent.GET_ELECTRON_APP_VERSION,
        )
        const webAppVersion = packageJson.version
        if (desktopAppVersion !== webAppVersion) {
          window.electron.invoke(RendererEvent.UPDATE_APP)
        }
      }
    }
    checkAppUpdate()
  }, [])
}
