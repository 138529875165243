import { ProductDetailItemType } from 'types'
import { tr } from '../pdfHelper'

const getProductHTML = (
  translations: Record<string, string>,
  product: ProductDetailItemType,
  image?: string,
) => {
  const orderNumber = tr(translations, 'order_number', 'Order Number')
  const ean = tr(translations, 'ean', 'EAN')
  const netPriceNumber = tr(translations, 'net_priceNumber', 'Price Net')
  const grossPriceNumber = tr(translations, 'gross_priceNumber', 'Price Gross')

  return `
    <div class="compare-col">
      ${image ? `<img src="${image}">` : ''}
      <span class="item-row bold">${product.name}</span>
      <span class="item-row bold">${orderNumber}: ${product.partnumberFormatted}</span>
      <span class="item-row bold">${ean}: ${product.ean}</span>
      ${
        product?.priceNetFormatted
          ? `<span class="item-row bold">${netPriceNumber}: ${product?.priceNetFormatted}</span>`
          : ''
      }
      ${
        product?.priceGrossFormatted
          ? `<span class="item-row bold">${grossPriceNumber}: ${product?.priceGrossFormatted}</span>`
          : ''
      }
    </div>
  `
}

export const getImageSection = (
  products: ProductDetailItemType[],
  translations: Record<string, string>,
) => {
  const images = products.map(
    (product) => product?.images?.[0]?.urls?.find((item) => item.type === 'full')?.url,
  )

  const productHTML = products
    .map((product, index) => getProductHTML(translations, product, images[index]))
    .join('')

  return `
    <div class="compare-row">
     <div class="compare-col"></div>
      ${productHTML}
    </div>
  `
}
