import { RendererEvent } from 'electron-shared'
import QRCode from 'qrcodejs2'
import { StructureItemType } from 'types'
import { loadImageForPDF } from 'config/utils/CommonFunction'

export const getBreadcrumbData = (pageId: number, structure: StructureItemType[] | null) => {
  if (!structure) return []
  const result = []

  let currentRouteId: number | null | undefined = pageId
  while (currentRouteId) {
    const route = structure.find((item) => item.id.toString() === currentRouteId?.toString())
    if (route) {
      result.push(route)
    }
    currentRouteId = route?.parentId
  }
  return result.reverse()
}

export const formatAnchorTag = (str: string) => {
  if (!str.includes('this.globalAppNavigation')) return str
  // remove href="#" onClick={() => {this.globalAppNavigation('pageId')}}
  return str.replace(/href="#" onClick=\{.*?\}\}/g, '')
}

const loadLocalImages = async (htmlDocument: Document) => {
  const images = htmlDocument.getElementsByTagName('img')
  const imagePromises = Array.from(images).map(async (img) => {
    try {
      const newImageUrl = await loadImageForPDF(img.src)
      img.src = newImageUrl
    } catch (error) {
      console.error(error)
    }
  })
  await Promise.all(imagePromises)
}

const createIframe = () => {
  const iframe = document.createElement('iframe')
  iframe.style.width = '1px'
  iframe.style.height = '1px'
  return iframe
}

// used for debug PDF HTML
const openNewPDFTab = (htmlDocument: Document) => {
  const newWin = window.open('', '_blank')
  newWin?.document.write(htmlDocument.documentElement.outerHTML)
}

const generateQRCode = async (htmlDocument: Document) => {
  const qrCodeElements = htmlDocument.getElementsByClassName('qr-code-url')
  if (!qrCodeElements.length) {
    return
  }

  Array.from(qrCodeElements).forEach((element) => {
    const id = element.id
    new QRCode(element, {
      text: id,
      width: 125,
      height: 125,
      colorDark: '#000000',
      colorLight: '#ffffff',
      correctLevel: QRCode.CorrectLevel.M,
    })
  })

  // wait for QR code to be generated
  await new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve()
    }, 1000)
  })
}

const printDocumentByWeb = (htmlDocument: Document, onDone: () => void) => {
  const iframe = createIframe()
  document.body.appendChild(iframe)

  // write content
  const content = iframe.contentDocument!
  content.open()
  content.write(htmlDocument.documentElement.outerHTML)
  content.close()

  const print = () => {
    document.title = content.title
    setTimeout(() => {
      iframe.contentWindow?.print()
      onDone()
    }, 300)

    // clean up
    setTimeout(() => {
      document.body.removeChild(iframe)
      document.title = 'Kärcher Programme'
    }, 3000)
  }

  // timeout for onload function 20s
  const timeout = setTimeout(() => {
    iframe.onload = function () {}
    print()
  }, 20 * 1000)

  iframe.onload = function () {
    clearTimeout(timeout)
    print()
  }
}

const printDocumentByDesktop = async (htmlDocument: Document, onDone: () => void) => {
  if (window.electron?.isDesktop()) {
    await loadLocalImages(htmlDocument)
    const fileName = await window.electron.asyncInvoke(
      RendererEvent.SAVE_PDF_TO_FILE,
      htmlDocument.documentElement.outerHTML,
      htmlDocument.title,
    )

    await window.electron.asyncInvoke(RendererEvent.OPEN_PDF, fileName)
    onDone()
  }
}

export const printDocument = async (htmlDocument: Document, onDone: () => void = () => {}) => {
  try {
    await generateQRCode(htmlDocument)

    if (window.electron?.isDesktop()) {
      await printDocumentByDesktop(htmlDocument, onDone)
    } else {
      printDocumentByWeb(htmlDocument, onDone)
    }
  } catch (error) {
    console.error('error printing document', error)
    onDone()
  }
}

export const tr = (translations: { [key: string]: string }, key: string, fallback: string = '') =>
  translations[key] || fallback
