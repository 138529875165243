import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import {
  AccessoryItemType,
  ProductDetailItemType,
  ProductListItemType,
  ProductListRootLineItemType,
} from 'types'
import { linkDataAnalytics } from 'config/adobeAnalytics/analytics'
import { PAGE_IDS, TRANSLATIONS } from 'config/constants'
import { selectTranslations } from 'redux/selector'
import useNavigation from 'hooks/useNavigation'
import DCAImage from 'components/DCAImage'
import 'styles/modal/confirmModal.scss'

export type ComparisonModalProps = {
  showModal: boolean
  productData?:
    | ProductListItemType
    | ProductDetailItemType
    | ProductListRootLineItemType
    | AccessoryItemType
  handleClose: () => void
}

const ComparisonModal = (props: ComparisonModalProps): React.ReactElement => {
  const { handleClose, productData, showModal } = props
  const translations = useSelector(selectTranslations)
  const { onDynamicNavigate } = useNavigation()

  const orderNumberTxt = translations.order_number ?? 'Order Number'
  const toComparisonTxt = translations.to_comparison ?? 'To Comparison'
  const continueTxt = translations.continue ?? 'Continue'
  const comparisonTitle = translations.item_added_to_comparison ?? TRANSLATIONS.comparisonTitle

  if (!productData) return <></>

  let imageFullUrl
  if ('images' in productData) {
    imageFullUrl = productData.images[0]?.urls?.find((img) => img.type === 'full')?.url
  } else if ('image' in productData) {
    imageFullUrl = productData.image
  }

  const onGoToComparison = () => {
    linkDataAnalytics('to_the_product_comparison')
    onDynamicNavigate(PAGE_IDS.COMPARISON_SCREEN)
    handleClose()
  }

  return (
    <Modal show={showModal} onHide={handleClose} size='sm' className='confirm-modal'>
      <Modal.Header closeButton>
        <Modal.Title className='confirm-modal-heading'>{comparisonTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md='5'>
            {imageFullUrl && (
              <Row>
                <DCAImage src={imageFullUrl} />
              </Row>
            )}
          </Col>
          <Col md='7' className='pt-4'>
            <div className='ak-bold'>{productData?.name}</div>
            {productData?.partnumberFormatted && (
              <div className='ak-bold'>
                {orderNumberTxt}: {productData?.partnumberFormatted}
              </div>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className='p-3'>
          <Button
            variant='secondary'
            className='app-button text-uppercase mx-3'
            onClick={handleClose}
          >
            {continueTxt}
          </Button>
          <Button
            variant='primary'
            className='app-button text-uppercase'
            onClick={onGoToComparison}
          >
            {toComparisonTxt}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ComparisonModal
